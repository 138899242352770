import {
  Box,
  Button,
  Collapse,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { registerBeneficiary, useUtils } from "api";
import { useAccount } from "contexts/Context";
import Pagination from "contexts/Pagination";
import Joi from "joi";
import { useState } from "react";
import { borderStyles } from "theme/border";

const BeneficiaryDetails = ({ beneficiary, onClose }) => {
  const { company_name, address, account_identifications } = beneficiary;
  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );

  return (
    <Box
      borderWidth="1px"
      p="4"
      maxW="100%"
      minHeight={{ lg: "80vh", sm: "60vw" }}
      background={background}
      css={borderStyles()}
      position="relative"
    >
      <Text fontSize={{ base: "xl", md: "2xl" }} my="30px" px="20px">
        Beneficiary Details
      </Text>
      <Table variant="simple">
        <Thead fontSize={{ lg: "lg", sm: "sm" }}>
          <Tr>
            <Th>Field</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        <Tbody fontSize={{ lg: "lg", sm: "xs" }}>
          <Tr>
            <Td>Company Name</Td>
            <Td>{company_name}</Td>
          </Tr>
          <Tr>
            <Td>Address</Td>
            <Td>
              {address.address_line_1}
              <br />
              {address.address_line_2 && `${address.address_line_2}`}
              <br />
              {address.city}
            </Td>
          </Tr>
          <Tr>
            <Td>Zip Code</Td>
            <Td>{address.zip_code}</Td>
          </Tr>
          <Tr>
            <Td>Country</Td>
            <Td>{address.country}</Td>
          </Tr>
          <Tr>
            <Td>Account Identifications</Td>
            <Td>
              <ul>
                {account_identifications.map((identification, index) => (
                  <li key={index}>
                    <strong>{identification.identification_type}:</strong>{" "}
                    {identification.identification_value}
                  </li>
                ))}
              </ul>
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
};

const AddBeneficiaryForm = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { benefaciaries: data, setRefreshAccounts } = useAccount();
  const { data: utils } = useUtils();
  const [small] = useMediaQuery("(max-width: 768px)");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeBenefaciary, setActiveBenefaciary] = useState();
  const [accountsPerPage] = useState(small ? 5 : 4);
  const indexOfLastAccount = currentPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;

  const currentBenefaciaries = data?.slice(
    indexOfFirstAccount,
    indexOfLastAccount
  );

  const [formData, setFormData] = useState({
    country: "",
    currency: "",
    beneficiary_type: "COMPANY",
    company_name: "",
    address_line_1: "",
    address_line_2: "",
    zip_code: "",
    city: "",
    account_identifications: [],
  });
  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  const modalBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)"
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleIdentificationChange = (e, index) => {
    const { name, value } = e.target;
    const updatedIdentifications = [...formData.account_identifications];
    updatedIdentifications[index] = {
      ...updatedIdentifications[index],
      [name]: value,
    };
    setFormData((prevFormData) => ({
      ...prevFormData,
      account_identifications: updatedIdentifications,
    }));
  };

  const handleAddIdentification = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      account_identifications: [
        ...prevFormData.account_identifications,
        {
          identification_type: "",
          identification_value: "",
          identification_category: "",
        },
      ],
    }));
  };

  const handleRemoveIdentification = (index) => {
    const updatedIdentifications = [...formData.account_identifications];
    updatedIdentifications.splice(index, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      account_identifications: updatedIdentifications,
    }));
  };

  const handleSubmit = async () => {
    // Validate form data
    const schema = Joi.object().keys({
      country: Joi.string().required(),
      beneficiary_type: Joi.string().required(),

      currency: Joi.string().required(),
      company_name: Joi.string().required(),
      address_line_1: Joi.string(),
      address_line_2: Joi.string(),
      zip_code: Joi.string(),
      city: Joi.string(),
      address_country: Joi.string(),
      account_identifications: Joi.array()
        .items(
          Joi.object({
            identification_type: Joi.string().required(),
            identification_value: Joi.string().required(),
            identification_category: Joi.string().required(),
          })
        )
        .required(),
    });

    const validationResult = schema.validate(formData);
    if (validationResult.error) {
      toast({
        title: validationResult.error.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    try {
      let form = formData;
      form.address_country = formData.country;
      await registerBeneficiary(form);

      toast({
        title: "Added benefaciary Successfully",
        status: "success",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      setRefreshAccounts(true);
      setFormData({
        country: "",
        currency: "",
        beneficiary_type: "COMPANY",
        company_name: "",
        address_line_1: "",
        address_line_2: "",
        zip_code: "",
        city: "",
        account_identifications: [],
      });
      onClose();
    } catch (error) {
      toast({
        title: error,
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
    }
  };

  // Define an array of form fields
  const formFields = [
    { name: "country", label: "Country", type: "text" },
    { name: "currency", label: "Currency", type: "text" },
    { name: "company_name", label: "Company Name", type: "text" },
    { name: "address_line_1", label: "Address Line 1", type: "text" },
    { name: "address_line_2", label: "Address Line 2", type: "text" },
    { name: "zip_code", label: "Zip Code", type: "text" },
    { name: "city", label: "City", type: "text" },
  ];

  const identificationTypes = [
    "ACCOUNT_NUMBER",
    "BANK_ACCOUNT_TYPE",
    "BANK_ADDRESS",
    "BANK_CODE",
    "BANK_NAME",
    "BIC",
    "BRANCH_CODE",
    "IBAN",
    "SORT_CODE",
    "SWIFT",
  ];

  return (
    <Box
      mt={{ lg: "12vh", sm: "8vh" }}
      // css={borderStyles()}
      // background={background}
    >
      <Flex
        justify={"space-between"}
        wrap={{ sm: "wrap-reverse", lg: "nowrap" }}
        align="flex-start"
        borderRadius={"30px"}
      >
        <Flex
          justifyContent={!currentBenefaciaries ? "center" : "start"}
          flexDirection={"column"}
          alignItems={"center"}
          width={{ lg: "48%", sm: "100%" }}
          padding={"30px"}
          minHeight={{ lg: "80vh", sm: "90vh" }} // Set a minimum height for the container
          borderRadius={"30px"}
          background={background}
          position={"relative"}
          pt={"10vh"}
          css={borderStyles()}
        >
          <Box
            display={"flex"}
            justifyContent={"start"}
            width={"100%"}
            position={"absolute"}
            top="30px"
            left="30px"
          >
            <Button
              css={borderStyles(false, true)}
              background={background}
              onClick={onOpen}
            >
              Add Beneficiary
            </Button>
          </Box>
          <Box paddingBottom="5vh" width={"100%"}>
            {currentBenefaciaries === undefined ? (
              <Spinner />
            ) : currentBenefaciaries?.length > 0 ? (
              currentBenefaciaries?.map((benefaciary) => (
                <Box
                  // key={benefaciary.id}
                  w={"100%"}
                  onClick={() => setActiveBenefaciary(benefaciary)}
                  background={background}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"start"}
                  flexDirection={"column"}
                  padding="10px 20px"
                  margin={{ lg: "10px 0px", sm: "20px 0px" }}
                  height="12vh"
                  borderRadius={"6px"}
                  cursor={"pointer"}
                  css={borderStyles(false, false)}
                >
                  <Text fontSize={{ lg: "2xl", sm: "sm" }}>
                    {benefaciary?.company_name}
                  </Text>
                  <Text fontSize={{ lg: "lg", sm: "xs" }}>
                    {benefaciary?.address.address_line_1}
                  </Text>
                </Box>
              ))
            ) : (
              <Text>No Benefeciaries Found</Text>
            )}
          </Box>
          <Flex
            justify={"center"}
            width={{ lg: "100%", sm: "100%" }}
            mt={5}
            position={"absolute"}
            left={"0%"}
            bottom={"20px"}
          >
            <Pagination
              currentPage={currentPage}
              paginate={setCurrentPage}
              itemsPerPage={accountsPerPage}
              totalItems={data?.length}
            />
          </Flex>
        </Flex>
        {activeBenefaciary && (
          <Box
            width={{ lg: "48%", sm: "100%" }}
            minHeight={{ lg: "70vh", sm: "90vh" }}
          >
            <BeneficiaryDetails
              beneficiary={activeBenefaciary}
              onClose={setActiveBenefaciary}
            />
          </Box>
        )}
      </Flex>
      {
        <>
          {isOpen && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backdropFilter: "blur(4px)",
                zIndex: 1000, // Adjust this value as needed
              }}
            />
          )}

          <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay blur={"100px"} />
            <ModalContent
              background={modalBg}
              css={{ ...borderStyles() }}
              width={{ lg: "100%", sm: "80%" }}
            >
              <ModalHeader>Add Beneficiary</ModalHeader>
              <ModalCloseButton />
              <ModalBody overflowY="auto">
                <Box>
                  {formFields.map((field, index) => (
                    <FormControl key={index} mb={4}>
                      <FormLabel>{field.label}</FormLabel>
                      {field.name === "currency" || field.name === "country" ? (
                        <Select name={field.name} onChange={handleChange}>
                          {utils?.countries
                            ?.filter(
                              (currency) =>
                                currency &&
                                currency.name &&
                                currency.name !== "" &&
                                currency.currency !== ""
                            )

                            ?.map((currency) => {
                              return (
                                <option
                                  key={currency.currency_code}
                                  style={{
                                    background: "rgba(0,0,0,0.9)",
                                    color: "white",
                                  }}
                                  value={
                                    field.name === "currency"
                                      ? currency?.currency_code
                                      : currency.iso_31663
                                  }
                                >
                                  {field.name === "currency"
                                    ? currency?.currency
                                    : currency.name}
                                </option>
                              );
                            })}
                        </Select>
                      ) : (
                        <Input
                          type={field.type}
                          name={field.name}
                          value={formData[field.name]}
                          onChange={handleChange}
                          placeholder={`Enter ${field.label}`}
                          color={"white"}
                          isRequired
                        />
                      )}
                    </FormControl>
                  ))}
                </Box>
                <FormControl mb={4}>
                  <FormLabel>Account Identifications</FormLabel>
                  {formData.account_identifications.map(
                    (identification, index) => (
                      <Collapse in={true}>
                        <Box
                          key={index}
                          mb={4}
                          width={"100%"}
                          display={"flex"}
                          flexDirection={{ lg: "row", sm: "column" }}
                          justifyContent={"space-between"}
                        >
                          <FormControl
                            mb={2}
                            display={"flex"}
                            width={{ lg: "25%", sm: "100%" }}
                          >
                            <Select
                              name="identification_type"
                              value={identification.identification_type}
                              onChange={(e) =>
                                handleIdentificationChange(e, index)
                              }
                              placeholder="Identification Type"
                              color={"white"}
                              isRequired
                            >
                              {identificationTypes.map((type, index) => (
                                <option
                                  key={index}
                                  value={type}
                                  style={{
                                    background: "rgba(0,0,0,0.9)",
                                    color: "white",
                                  }}
                                >
                                  {type.replace("_", " ")}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl mb={2} width={{ lg: "25%", sm: "100%" }}>
                            <Input
                              type="text"
                              name="identification_value"
                              placeholder="Identification Value"
                              value={identification.identification_value}
                              color={"white"}
                              onChange={(e) =>
                                handleIdentificationChange(e, index)
                              }
                              isRequired
                            />
                          </FormControl>
                          <FormControl mb={2} width={{ lg: "25%", sm: "100%" }}>
                            <Select
                              name="identification_category"
                              value={identification.identification_category}
                              onChange={(e) =>
                                handleIdentificationChange(e, index)
                              }
                              placeholder="Identification Category"
                              isRequired
                            >
                              <option
                                value="LOCAL"
                                style={{
                                  background: "rgba(0,0,0,0.9)",
                                  color: "white",
                                }}
                              >
                                LOCAL
                              </option>
                              <option
                                value="GLOBAL"
                                style={{
                                  background: "rgba(0,0,0,0.9)",
                                  color: "white",
                                }}
                              >
                                GLOBAL
                              </option>
                            </Select>
                          </FormControl>
                          <Button
                            colorScheme="red"
                            variant="outline"
                            size="sm"
                            onClick={() => handleRemoveIdentification(index)}
                          >
                            Remove
                          </Button>
                        </Box>
                      </Collapse>
                    )
                  )}
                  <Button
                    onClick={handleAddIdentification}
                    background={background}
                    css={borderStyles("30px", true)}
                  >
                    Add Identification
                  </Button>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  background={background}
                  css={borderStyles("30px", true)}
                  mr={3}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
                <Button
                  onClick={onClose}
                  background={background}
                  css={borderStyles("30px", true)}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      }
    </Box>
  );
};

export default AddBeneficiaryForm;
