import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Lorem
} from '@chakra-ui/react'

export default function TermsModal() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} variant='ghost' fontSize={14} color={"#898989"}>Terms of use</Button>

      <Modal isOpen={isOpen} onClose={onClose} co>
        <ModalOverlay />
        <ModalContent background="#242424">
          <ModalHeader>Terms of use</ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize={12} display="flex" flexDir="column" gap={2}>
            <p>
              Paymob Technology Ltd, trading as Dapio registered in England
              and Wales at 71-75 Shelton Street, Covent Garden, London, WC2H
              9JQ. Company registration number 11965837. Dapio is registered
              with the FCA as a Small Payments Institution under Reference
              number: 917808.
            </p>
            <p>
              The Financial Ombudsman Service may be able to resolve your
              complaint against this firm if the firm fails to deal with it
              properly.
            </p>
            <p>
              Monavate Limited (registered number 12472532), Suite EF38, The
              Officers Mess Business Centre Royston Road, Duxford,
              Cambridge, CB22 4QH, United Kingdom. Monavate is authorised by
              the Financial Conduct Authority under the Electronic Money
              Regulations 2011 (register reference 901097) and UAB MONAVATE
              (Legal Entity Code: 305628001), a company incorporated in
              Lithuania whose registered office is at Giruliu str. 10-201,
              Vilnius, Lithuania is a licensed E-Money institution, both
              entities are licensed by Visa and MasterCard as card issuers
              in certain territories. Card Provider Monavate Limited
              (registered number 12472532), Suite EF38, The Officers Mess
              Business Centre Royston Road, Duxford, Cambridge, CB22 4QH,
              United Kingdom. Monavate is authorised by the Financial
              Conduct Authority under the Electronic Money Regulations 2011
              (register reference 901097) and UAB MONAVATE (Legal Entity
              Code: 305628001), a company incorporated in Lithuania whose
              registered office is at Giruliu str. 10-201, Vilnius,
              Lithuania is a licensed E-Money institution, both entities are
              licensed by Visa and MasterCard as card issuers in certain
              territories.
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
