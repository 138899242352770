import { useState } from "react";
// Chakra imports
import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import { verifyOtp } from "api/auth";
import illustration from "assets/img/auth/auth.png";
import OtpInput from "react-otp-input";
import { borderStyles } from "theme/border";

function VerifyOtp() {
  const textColorBrand = useColorModeValue("brand.500", "white");
  const background = useColorModeValue("whiteAlpha.900", "whiteAlpha.100");
  const auth = JSON.parse(localStorage.getItem("authFlows"));
  const [errors, setErrors] = useState();
  const [data, setData] = useState();

  const handleChange = (data) => {
    setData(data);
    setErrors(false);
  };

  const handleBack = () => {
    localStorage.removeItem("authFlows");
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    window.location.reload();
  };

  const handleSubmit = async () => {
    if (data && data?.length !== 6) {
      setErrors(true);
      return;
    } else if (
      auth.emailAuthStatus === "ENABLED" ||
      auth.appAuthStatus === "ENABLED"
    ) {
      setErrors(false);
      try {
        await verifyOtp(
          data,
          auth.emailAuthStatus === "ENABLED"
            ? "emailAuthStatus"
            : "appAuthStatus"
        );
      } catch (error) {
        setErrors(true);
      }
    }
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40vh", md: "14vh", lg: "25vh", sm: "15vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Text
            mb="36px"
            ms="4px"
            color={textColorBrand}
            fontWeight="400"
            fontSize="3xl"
          >
            Enter your OTP
          </Text>
          <Text
            mb="36px"
            ms="4px"
            color={textColorBrand}
            fontWeight="400"
            fontSize="md"
          >
            {auth?.emailAuthStatus === "ENABLED"
              ? "Enter your OTP which you received on mail"
              : auth.appAuthStatus === "ENABLED"
              ? "Enter your OTP From Authenticator App"
              : ""}
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          justify={"center"}
          align={"center"}
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <OtpInput
            value={data && data}
            onChange={handleChange}
            numInputs={6}
            renderInput={(props) => (
              <input
                {...props}
                style={{
                  background: "transparent",
                  height: "50px",
                  width: "50px",
                  border: errors ? "1px solid red" : "1px solid white",
                  borderRadius: "12px",
                  textAlign: "center",
                  fontSize: "20px",
                  color: "white",
                  margin: "30px 3px",
                }}
              />
            )}
          />
          <Button
            fontSize="sm"
            background={background}
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={handleSubmit}
            css={borderStyles("30px", false)}
          >
            Confirm
          </Button>
          <Button
            fontSize="sm"
            background={background}
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            css={borderStyles("30px", false)}
            onClick={handleBack}
          >
            Go Back
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default VerifyOtp;
