import { Box, Flex, Image, Stack } from "@chakra-ui/react";
import logo from "assets/img/layout/logoWhite.svg";
import Links from "components/sidebar/components/Links";
import TermsDropdown from "./TermsDropdown";
import TermsModal from "./TermsModal";

function SidebarContent(props) {
  const { routes, onClose } = props;

  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Flex justify={"center"} align={"center"}>
        <Image src={logo} mt="10px" mb="30px" width={{ sm: "100px" }} />
      </Flex>

      <Stack
        direction="column"
        mb="auto"
        mt="8px"
        sx={{ mt: { sm: "30px", lg: "50px" } }}
      >
        <Box ps="20px" pe={{ md: "16px", "2xl": "10px" }}>
          <Links routes={routes} onClose={onClose} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" flexDir="column">
          <TermsModal/>
          <TermsDropdown />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
