/* eslint-disable */
import { BASE_URL } from "Baseurl";
import { useEffect, useState } from "react";
import apiInstance from "./interceptor";

// Function to handle registering a user
export const orderNewCard = async (userData) => {
  try {
    const response = await apiInstance.post(
      `${BASE_URL}/card/new/order`,
      userData
    );
    return response.data;
  } catch (error) {
    throw error.response.message;
  }
};
export const getFullCardNumber = async (cardNumber) => {
  try {
    const response = await apiInstance.get(
      `${BASE_URL}/card/number/read/v2/${cardNumber}`
    );
    return response.data;
  } catch (error) {
    throw error.response.message;
  }
};

export const getCVV = async (cardNumber) => {
  try {
    const response = await apiInstance.get(
      `${BASE_URL}/card/cvv/read/v2/${cardNumber}`
    );
    return response.data;
  } catch (error) {
    throw error.response.message;
  }
};
export const getPin = async (cardNumber) => {
  try {
    const response = await apiInstance.get(
      `${BASE_URL}/card/pin/read/v2/${cardNumber}`
    );
    return response.data;
  } catch (error) {
    throw error.response.message;
  }
};
export const resetPin = async (cardNumber) => {
  try {
    const response = await apiInstance.get(
      `${BASE_URL}/card/pin/reset/${cardNumber}`
    );
    return response.data;
  } catch (error) {
    throw error.response.message;
  }
};
export const activateCard = async (cardNumber) => {
  try {
    const response = await apiInstance.post(
      `${BASE_URL}/card/activate/${cardNumber}`
    );
    return response.data;
  } catch (error) {
    throw error.response.message;
  }
};

export function useCardList(indicator) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const user = localStorage.getItem("user");
  const fetch = async () => {
    if (user) {
      try {
        const response = await apiInstance.get(`${BASE_URL}/card/list`);
        setData(response?.data.data);
        return;
      } catch (error) {
        setError(true);
      }
    }
  };
  useEffect(() => {
    fetch();
    console.log("hookcalled");
  }, [indicator]);
  return { data, error };
}
