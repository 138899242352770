// Import necessary components and hooks
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { registerAccount, useCardList } from "api";
import Copy from "assets/img/layout/copy.svg";
import { useAccount } from "contexts/Context";
import Pagination from "contexts/Pagination";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { borderStyles } from "theme/border";
import { validateAccount } from "validations";
import UserActivity from "../default/components/WeeklyRevenue";

const Account = () => {
  const [newAccount, setNewAccount] = useState({
    type: "business",
  });
  const { changeAccount, accounts, setRefreshAccounts } = useAccount();

  const { data: cards } = useCardList();
  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeAccount, setActiveAccount] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [accountsPerPage] = useState(4);
  const indexOfLastAccount = currentPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
  const [currentAccounts, setCurrentAccounts] = useState(false);
  const toast = useToast();

  const activeCard = cards?.filter(
    (card) => Number(activeAccount?.id) === card.account_id
  );

  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  const modalBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)"
  );

  const handleInputChange = (e) => {
    setNewAccount({
      ...newAccount,
      [e.target.name]: e.target.value,
    });
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };

  const handleAddAccount = async () => {
    const validationErrors = validateAccount(newAccount);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await registerAccount(newAccount);
      setIsOpen(false);
      setNewAccount({});
      setRefreshAccounts(true);
      toast({
        title: "Account added.",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      toast({
        title: "Account failed.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  };
  const copyToClipboard = (text) => {
    // Check if the Clipboard API is available
    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }

    // Copy text to the clipboard
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: "Account id copied ",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        // Optionally, you can show a success message or perform other actions
      })
      .catch((error) => {
        console.error("Failed to copy text to clipboard:", error);
        // Handle errors
      });
  };
  useEffect(() => {
    setCurrentAccounts(
      search !== ""
        ? accounts
            ?.filter((account) =>
              account.name.toLowerCase().includes(search.toLowerCase())
            )
            .slice(0, 4)
        : accounts?.slice(indexOfFirstAccount, indexOfLastAccount)
    );
  }, [accounts, search, indexOfLastAccount, indexOfFirstAccount]);

  return (
    <Box mt={{ lg: !accounts ? "12vh" : "12vh", sm: "4vh" }} width={"100%"}>
      {!accounts ? (
        <SkeletonTheme
          baseColor={"#646466"}
          highlightColor={"#a2a2a3"}
          width={"100%"}
        >
          <Skeleton
            borderRadius={30}
            width={{ lg: "83vw", sm: "100vw" }}
            height="80vh"
          />
        </SkeletonTheme>
      ) : (
        <Flex
          justify={"space-between"}
          wrap={{ sm: "wrap-reverse", lg: "nowrap" }}
          align="flex-start"
          minH={"100%"}
        >
          <Flex
            justifyContent={
              currentAccounts === undefined || currentAccounts.length === 0
                ? "center"
                : "start"
            }
            css={borderStyles()}
            flexDirection={"column"}
            alignItems={"center"}
            width={{ lg: "48%", sm: "100%" }}
            padding={"30px"}
            minHeight={{ lg: "80vh", sm: "90vh" }} // Set a minimum height for the container
            maxHeight={{ lg: "80vh", sm: "90vh" }} // Set a minimum height for the container
            borderRadius={"30px"}
            background={background}
            py="30px"
            mt={{ sm: "5vh", lg: "0" }}
            position={"relative"}
          >
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={{ sm: "column", lg: "row" }}
              justifyContent={"space-between"}
              position={"absolute"}
              top="0"
              left={"0"}
              padding={{ lg: "30px", sm: "10px 30px" }}
            >
              <Button
                leftIcon={<AddIcon />}
                onClick={() => setIsOpen(true)}
                variant="solid"
                background={background}
                css={borderStyles("10px", true)}
                width={{ sm: "100%", lg: "auto" }}
              >
                Add Account
              </Button>
              <Box
                css={borderStyles("10px", true)}
                mt={{ lg: "0px", sm: "10px" }}
              >
                <Input
                  placeholder="Search accounts"
                  width="200px"
                  border="none"
                  outline={"none"}
                  background={background}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  color="white"
                />
              </Box>
            </Box>
            <Box
              width={"100%"}
              height={"90%"}
              mt={
                currentAccounts === undefined || currentAccounts.length === 0
                  ? "center"
                  : "70px"
              }
            >
              {currentAccounts?.length > 0 ? (
                currentAccounts?.map((account) => (
                  <Box
                    key={account.id}
                    w={"100%"}
                    css={borderStyles(false, false)}
                    onClick={() => {
                      setActiveAccount(account);
                      changeAccount(account?.id);
                    }}
                    background={background}
                    padding="10px 20px"
                    margin="15px 0px"
                    height={"12vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"start"}
                    borderRadius={"6px"}
                    cursor={"pointer"}
                  >
                    <Text fontSize={{ lg: "2xl", sm: "lg" }}>
                      {account?.name}
                    </Text>
                    <Text fontSize={{ lg: "xl", sm: "md" }}>
                      {account?.balance} {account?.currency}
                    </Text>
                  </Box>
                ))
              ) : (
                <Text width={"100%"} textAlign={"center"}>
                  No Accounts Found
                </Text>
              )}
            </Box>
            <Flex
              justify={"center"}
              width={{ lg: "100%", sm: "100%" }}
              mt={5}
              position={"absolute"}
              left={"0%"}
              bottom={"20px"}
            >
              <Pagination
                currentPage={currentPage}
                paginate={setCurrentPage}
                itemsPerPage={accountsPerPage}
                totalItems={accounts?.length}
              />
            </Flex>
          </Flex>
          {activeAccount && (
            <Flex
              width={{ lg: "48%", sm: "100%" }}
              direction={"column"}
              justify="start"
              align={"start"}
              borderRadius={"20px"}
              minHeight={"80vh"}
              background={background}
              css={borderStyles()}
              px={"30px"}
              py={"10px"}
            >
              <Box
                background={background}
                width={{ base: "100%", md: "38%", lg: "100%" }}
                p={4}
                margin="10px 0px"
                mb={{ lg: 4, sm: 2 }}
                borderRadius={10}
                display={"flex"}
                flexDirection={"column"}
                height={"15vh"}
                css={borderStyles(false, true)}
              >
                <Text
                  fontSize={{ lg: "xl", sm: "md" }}
                  fontWeight="bold"
                  mb={2}
                >
                  {activeAccount?.name.split(" ")[0]}'s Portfolio
                </Text>
                <Text
                  fontSize={"24px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  {activeAccount?.balance} {activeAccount?.currency}
                  <Text display={"flex"} alignItems={"center"} fontSize={"sm"}>
                    {"#".concat(activeAccount.id)}{" "}
                    <Image
                      src={Copy}
                      ml="5px"
                      onClick={() => copyToClipboard(activeAccount.id)}
                    />
                  </Text>
                </Text>
              </Box>
              <Box
                width={{ base: "100%", md: "100%", lg: "100%" }}
                mb={{ lg: 4, sm: 2 }}
                borderRadius={10}
                background={background}
                align="center"
                display={"flex"}
                flexDirection={"column"}
                css={borderStyles(false, true)}
                height={"15vh"}
                justifyContent={"space-evenly"}
                padding={5}
              >
                <Text
                  fontSize={{ lg: "xl", sm: "md" }}
                  fontWeight="bold"
                  mb={1}
                  width={"100%"}
                  textAlign={"start"}
                >
                  Cards
                </Text>
                <Flex justify={"space-evenly"} width={"100%"}>
                  {activeCard && activeCard?.length > 0 ? (
                    activeCard?.map(
                      (card, index) =>
                        index < 2 && (
                          <NavLink to={"/admin/cards"}>
                            <Box
                              key={card.id}
                              css={borderStyles(false, true)}
                              background={background}
                              padding="0px 20px"
                              borderRadius={"20px"}
                              cursor={"pointer"}
                            >
                              <Text fontSize={{ lg: "xl", sm: "md" }}>
                                {card.emboss_name}
                              </Text>
                            </Box>
                          </NavLink>
                        )
                    )
                  ) : (
                    <Text>No card associated</Text>
                  )}
                </Flex>
              </Box>
              <Box style={{ minHeight: "40vh", width: "100%" }}>
                <UserActivity
                  mainaccount={activeAccount && activeAccount}
                  accountSection={true}
                />
              </Box>
            </Flex>
          )}
          {
            <>
              {isOpen && (
                <div
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backdropFilter: "blur(4px)",
                    zIndex: 1000, // Adjust this value as needed
                  }}
                />
              )}

              <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                background={"black"}
                size="2xl"
                isCentered
              >
                <ModalOverlay />
                <ModalContent
                  width={{ base: "100%", sm: "80%" }}
                  background={modalBg}
                  // border={"1px solid gray"}
                  css={borderStyles()}
                >
                  <ModalHeader>Add Account</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <FormControl isInvalid={errors.name}>
                      <FormLabel>Account Name</FormLabel>
                      <Input
                        name="name"
                        placeholder="Account Name"
                        value={newAccount.name || ""}
                        onChange={handleInputChange}
                        color="white"
                        type="text"
                      />
                      {errors.name && (
                        <FormErrorMessage>{errors.name}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl mt={4} isInvalid={errors.type}>
                      <FormLabel>Account Type</FormLabel>
                      <Select
                        name="type"
                        color="white"
                        value={newAccount.type || ""}
                        onChange={handleInputChange}
                      >
                        {/* <option value="personal" style={{ color: "white" }}>
                      Personal
                    </option> */}
                        <option
                          value="business"
                          style={{
                            background: "rgba(0,0,0,0.9)",
                            color: "white",
                          }}
                        >
                          Business
                        </option>
                        {/* <option value="technical" style={{ color: "white" }}>
                      Techincal
                    </option> */}
                      </Select>
                      {errors.type && (
                        <FormErrorMessage>{errors.type}</FormErrorMessage>
                      )}
                    </FormControl>
                    <FormControl mt={4} isInvalid={errors.currency}>
                      <FormLabel>Currency</FormLabel>
                      <Select
                        name="currency"
                        placeholder="Select Currency "
                        value={newAccount.currency || ""}
                        onChange={handleInputChange}
                      >
                        <option value="GBP" style={{ color: "white" }}>
                          GBP
                        </option>
                        <option value="USD" style={{ color: "white" }}>
                          USD
                        </option>
                        <option value="EUR" style={{ color: "white" }}>
                          EUR
                        </option>
                      </Select>
                      {errors.currency && (
                        <FormErrorMessage>{errors.currency}</FormErrorMessage>
                      )}
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      background={background}
                      css={borderStyles("30px", true)}
                      mr={3}
                      onClick={handleAddAccount}
                    >
                      Add
                    </Button>
                    <Button
                      background={background}
                      css={borderStyles("30px", true)}
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
          }
        </Flex>
      )}
    </Box>
  );
};

export default Account;
