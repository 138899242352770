// Chakra Imports
import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
// Assets
import navImage from "assets/img/layout/Navbar.png";
import avatar from "assets/img/layout/avatar.svg";
import { adminRoutes as routes } from "routes.js";

export default function HeaderLinks(props) {
  const { Address } = props;
  // Chakra Color Mode
  let menuBg = useColorModeValue("#0E0E0E", "#0E0E0E");

  return (
    <Flex
      w={{ sm: "auto", md: "auto" }}
      alignItems="center"
      justifyContent={"end"}
      transform={{ sm: "translateX(40px)", lg: "none" }}
      flexDirection="row"
      bg={menuBg}
      // flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
    >
      <SidebarResponsive routes={routes} Address={Address} />

      <Menu>
        <MenuList
          p="20px"
          me={{ base: "30px", md: "unset" }}
          borderRadius="20px"
          bg={menuBg}
          border="none"
          mt="22px"
          minW={{ base: "auto" }}
          maxW={{ base: "360px", md: "auto" }}
        >
          <Image src={navImage} borderRadius="16px" />
        </MenuList>
      </Menu>
      <Menu>
        <MenuButton p="0px">
          <Image
            src={avatar}
            borderRadius="16px"
            mx={"10px"}
            width={{ sm: "30px", lg: "60px" }}
          />
        </MenuButton>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
