import { Avatar, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import {
  MdArrowCircleLeft,
  MdArrowCircleRight,
  MdSwapVert,
} from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { borderStyles } from "theme/border";
import ta from "time-ago";

function Transactions({
  data,
  setItem,
  activeAccount,
  main,
  height,
  another,
  accountSection,
}) {
  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );

  const history = useHistory();

  const renderTransactionIcon = (type) => {
    switch (type) {
      case "sent":
        return (
          <Icon
            as={MdArrowCircleRight}
            color="red.500"
            style={{
              position: "absolute",
              width: { lg: "30px", sm: "20px" },
              height: { lg: "30px", sm: "20px" },
              bottom: "-2px",
              right: "-4px",
            }}
          />
        );
      case "received":
        return (
          <Icon
            as={MdArrowCircleLeft}
            color="green.500"
            style={{
              position: "absolute",
              width: { lg: "30px", sm: "20px" },
              height: { lg: "30px", sm: "20px" },
              bottom: "-2px",
              right: "-4px",
            }}
          />
        );
      case "converted":
        return (
          <Icon
            as={MdSwapVert}
            color="white"
            style={{
              position: "absolute",
              width: { lg: "30px", sm: "20px" },
              height: { lg: "30px", sm: "20px" },
              bottom: "-2px",
              right: "-4px",
              background: "blue",
              borderRadius: "50%",
            }}
            css={borderStyles}
          />
        );
      default:
        return null;
    }
  };
  function classifyTransaction(transaction, accountToCheck) {
    if (transaction.sender.account_id === accountToCheck) {
      return "sent";
    } else if (transaction.receiver.account_id === accountToCheck) {
      return "received";
    } else {
      return "swap";
    }
  }
  return data?.map((transaction, index) =>
    !main || accountSection ? (
      index < (accountSection ? 1 : 3) ? (
        <Flex
          key={transaction.id}
          p="3"
          boxShadow="md"
          bg={background}
          justify={"space-between"}
          borderRadius={!another ? "20px" : "5px"}
          background={background}
          align={"center"}
          my={"10px"}
          width={another ? "auto" : "100%"}
          cursor={"pointer"}
          css={borderStyles(false, true)}
          onClick={() =>
            main
              ? setItem({
                  ...transaction,
                  type: classifyTransaction(transaction, activeAccount),
                })
              : history.push("/admin/transactions")
          }
        >
          <Flex align="center" justify={another ? "space-between" : "start"}>
            {!another && (
              <Flex position={"relative"}>
                <Avatar
                  _hover={{ cursor: "pointer" }}
                  color="white"
                  name={
                    classifyTransaction(transaction, activeAccount) === "sent"
                      ? transaction.receiver.name
                      : transaction.sender.name
                  }
                  bg={background}
                  size="sm"
                  css={borderStyles("50%", true)}
                  w={{ lg: "60px", sm: "40px" }}
                  h={{ lg: "60px", sm: "40px" }}
                />
                {renderTransactionIcon(
                  classifyTransaction(transaction, activeAccount)
                )}
              </Flex>
            )}

            <Flex
              flexDirection={"column"}
              align="flex-start"
              marginLeft={"10px"}
            >
              {!another && (
                <Text fontSize={{ lg: "lg", sm: "sm" }}>
                  {classifyTransaction(transaction, activeAccount) === "sent"
                    ? transaction.receiver.name
                    : transaction.sender.name}
                </Text>
              )}
              {!another && (
                <Text fontSize={{ lg: "lg", sm: "xs" }}>
                  {ta.ago(transaction.payment_date)}
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex>
            <Text
              fontSize={{ lg: "lg", sm: "xs" }}
              padding={another && "5px 20px"}
            >
              {transaction?.amount} {transaction?.converted_currency}
            </Text>
          </Flex>
        </Flex>
      ) : (
        ""
      )
    ) : (
      <Flex
        key={transaction.id}
        p="3"
        cursor={"pointer"}
        boxShadow="md"
        bg={background}
        justify={"space-between"}
        borderRadius={"20px"}
        align={"center"}
        my={"5px"}
        width={"100%"}
        height={"11vh"}
        css={borderStyles(false, true)}
        onClick={() =>
          main
            ? setItem({
                ...transaction,
                type: classifyTransaction(transaction, activeAccount),
              })
            : history.push("/admin/transactions")
        }
      >
        <Flex align="center" justify="start">
          <Flex position={"relative"}>
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={
                classifyTransaction(transaction, activeAccount) === "sent"
                  ? transaction.receiver.name
                  : transaction.sender.name
              }
              bg={background}
              css={borderStyles("50%", true)}
              size="sm"
              w={{ lg: "60px", sm: "40px" }}
              h={{ lg: "60px", sm: "40px" }}
            />
            {renderTransactionIcon(
              classifyTransaction(transaction, activeAccount)
            )}
          </Flex>

          <Flex flexDirection={"column"} align="flex-start" marginLeft={"10px"}>
            <Text fontSize={{ lg: "lg", sm: "xs" }}>
              {classifyTransaction(transaction, activeAccount) === "sent"
                ? transaction.receiver.name
                : transaction.sender.name}
            </Text>
            <Text fontSize={{ lg: "lg", sm: "xs" }}>
              {ta.ago(transaction.payment_date)}
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <Text fontSize={{ lg: "lg", sm: "sm" }}>
            {transaction?.amount} {transaction?.converted_currency}
          </Text>
        </Flex>
      </Flex>
    )
  );
}

export default Transactions;
