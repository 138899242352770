// Chakra Imports
import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.svg";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const { secondary, message, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let navbarPosition = "fixed";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue("#0E0E0E", "#0E0E0E");
  let navbarBorder = "transparent";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      w={"100vw"}
      display={"flex"}
      py={{ lg: "20px", sm: "10px" }}
      justifyContent={{ xl: "space-between", lg: "space-between" }}
      px={"30px"}
      borderBottom="1px solid #313131"
    >
      <Image
        src={logoWhite}
        sx={{ width: { lg: "15%", sm: "120px", md: "200px" } }}
        mr="100px"
      />
      <Flex
        w="80%"
        flexDirection={{
          sm: "row",
          md: "row",
        }}
        justify={"space-between"}
        alignItems={{ xl: "center" }}
        mb={gap}
      >
        <Box mb={{ sm: "8px", md: "0px" }} display={{ sm: "none", lg: "flex" }}>
          {/* Here we create navbar brand, based on route name */}
          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontSize={{ sm: "20px", lg: "25px" }}
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            {brandText}
          </Link>
        </Box>
        <Flex justifyContent={"space-evenly"} w={{ sm: "100%", lg: "30%" }}>
          <Flex
            alignItems={"center"}
            width={"40%"}
            display={{ sm: "none", lg: "flex" }}
          >
            {/* <Text mr="5px" fontSize={{ sm: "10px", md: "12px", lg: "17px" }}>
              0X45......323
            </Text> */}
            {/* <Image src={copy} onClick={() => alert("Copied")} /> */}
          </Flex>

          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
            Address={""}
          />
        </Flex>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
