import { ChakraProvider } from "@chakra-ui/react";
import { useCheckAuth } from "api";
import "assets/css/App.css";
import { AccountProvider } from "contexts/Context";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import KybLayout from "layouts/kyb";
import VerificationLayout from "layouts/verification";
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import theme from "theme/theme";
import Logo from "./assets/img/layout/logoWhite.svg";
import "./index.css";

const PrivateRoute = ({
  component: Component,
  isAuth,
  isVerified,
  isKyc,
  strict,
  kyb,
  authFlows,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isKyc && isAuth) {
          return kyb ? (
            <Component {...props} />
          ) : (
            <Redirect to="/admin/default" />
          );
        } else if (strict) {
          if (authFlows || props.location.pathname === "/auth/verify-otp") {
            return authFlows &&
              props.location.pathname === "/auth/verify-otp" ? (
              <Component {...props} />
            ) : props.location.pathname === "/auth/verify-otp" ? (
              <Redirect to="/auth/login" />
            ) : (
              <Redirect to="/auth/verify-otp" />
            );
          } else {
            return isAuth ? (
              <Component {...props} />
            ) : isVerified ? (
              <Redirect to="/verification/email-verification" />
            ) : kyb ? (
              <Redirect to="/kyb/verification" />
            ) : (
              <Redirect to="/admin/default" />
            );
          }
        } else {
          // If not in strict mode, check for authentication, email verification, and KYC status
          if (!isAuth) {
            // If user is not authenticated, redirect to /auth/login
            return <Redirect to="/auth/login" />;
          } else if (!isVerified) {
            // If user is not email verified, redirect to /verification/email-verification
            return <Redirect to="/verification/email-verification" />;
          } else if (!kyb) {
            // If user is not KYC verified, redirect to /kyb
            return isVerified ? (
              <Redirect to="/kyb/verification" />
            ) : (
              <Redirect to="/verification/email-verification" />
            );
          } else {
            // If user is authenticated, email verified, and KYC verified, allow access to the requested component
            return <Component {...props} />;
          }
        }
      }}
    />
  );
};

const App = () => {
  const isAuthenticated = useCheckAuth();

  return (
    <AccountProvider>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          {isAuthenticated === null ? (
            <div className="container">
              <img src={Logo} alt="Sample" className="animated-image" />
            </div>
          ) : (
            <HashRouter>
              <Switch>
                <PrivateRoute
                  path="/admin"
                  component={AdminLayout}
                  isAuth={isAuthenticated?.isAuth}
                  kyb={isAuthenticated?.isKyb}
                  isVerified={isAuthenticated?.isVerified}
                />
                <PrivateRoute
                  path="/auth"
                  component={AuthLayout}
                  isAuth={
                    isAuthenticated.authFlows
                      ? isAuthenticated.isAuth
                      : !isAuthenticated?.isAuth
                  }
                  isVerified={!isAuthenticated?.isVerified}
                  kyb={!isAuthenticated?.isKyb}
                  strict={true}
                  authFlows={isAuthenticated.authFlows}
                />
                <PrivateRoute
                  path="/verification"
                  component={VerificationLayout}
                  isAuth={isAuthenticated?.isAuth}
                  isVerified={isAuthenticated?.isVerified}
                  kyb={isAuthenticated?.isKyb}
                  strict={true}
                  authFlows={isAuthenticated.authFlows}
                />
                <PrivateRoute
                  path="/kyb"
                  isKyc={true}
                  isAuth={isAuthenticated?.isAuth}
                  kyb={!isAuthenticated?.isKyb}
                  isVerified={isAuthenticated?.isVerified}
                  component={KybLayout}
                  authFlows={isAuthenticated.authFlows}
                />
                <Redirect to="/admin" />
              </Switch>
            </HashRouter>
          )}
        </React.StrictMode>
      </ChakraProvider>
    </AccountProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));