import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";

import { useGetHistory } from "api";
import Card from "components/card/Card.js";
import { useAccount } from "contexts/Context";
import { MdHistory } from "react-icons/md";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { borderStyles } from "theme/border";
import Transactions from "views/admin/transactions/transactions";

// Tab component
const Tab = ({ title, active, onClick }) => {
  const tabBg = useColorModeValue("whiteAlpha.900", "whiteAlpha.100");

  return (
    <Button
      onClick={onClick}
      bg={active ? tabBg : "transparent"}
      color={"white"}
      fontWeight={active ? "bold" : "normal"}
      fontSize={{ sm: "8px", lg: "lg" }}
      css={borderStyles(false, true)}
      mx="4px"
    >
      {title}
    </Button>
  );
};

export default function WeeklyRevenue(props) {
  const { selectedAccount, another, accountSection, ...rest } = props;
  const { history, loading } = useGetHistory(selectedAccount);
  const { account } = useAccount();

  // State for active tab
  const [activeTab, setActiveTab] = useState("all");
  const bg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );

  const filteredTransactions =
    activeTab === "sent"
      ? history?.filter(
          (transaction) => transaction.sender.account_id === account
        )
      : activeTab === "received"
      ? history?.filter(
          (transaction) => transaction.receiver.account_id === account
        )
      : history?.filter(
          (transaction) =>
            transaction.receiver.account_id === account ||
            transaction.sender.account_id === account
        );

  // Filter transactions based on active tab

  return (
    <Card
      align="center"
      direction="column"
      w="100%"
      bg={bg}
      {...rest}
      css={borderStyles()}
      minHeight={accountSection && "40vh"}
    >
      <Flex
        align={{ lg: "start", sm: "center" }}
        w="100%"
        px="15px"
        py="10px"
        direction={"column"}
      >
        <Flex justify={"space-between"} w={"100%"} align={"center"} mb="20px">
          <Flex align={"center"} width={{ sm: "40%", lg: "60%" }}>
            <Icon
              as={MdHistory}
              width={{ sm: "30px", base: "40px" }}
              height={{ sm: "30px", base: "40px" }}
              sx={{
                background: bg,
                padding: "5px",
                borderRadius: "20%",
                marginRight: "10px",
              }}
            />
            <Text
              me="auto"
              textAlign={"left"}
              color={"white"}
              fontSize={{ sm: "13px", lg: "xl" }}
              fontWeight="700"
              lineHeight="100%"
            >
              Recent Transactions
            </Text>
          </Flex>
          {/* {!another && (
            <Select
              width={{ sm: "30%", lg: "35%" }}
              onChange={(e) => setAccount(e.target.value)}
              value={account}
            >
              <option value={"all"} style={{ color: "white" }}>
                All accounts
              </option>
              {accounts?.map((account) => (
                <option style={{ color: "white" }} value={account.id}>
                  {account.name}
                </option>
              ))}
            </Select>
          )} */}
        </Flex>
        {!another && (
          <Flex justify={"center"} align={"center"}>
            <Tab
              title="All"
              active={activeTab === "all"}
              onClick={() => setActiveTab("all")}
            />
            <Tab
              title="Sent"
              active={activeTab === "sent"}
              onClick={() => setActiveTab("sent")}
            />
            <Tab
              title="Received"
              active={activeTab === "received"}
              onClick={() => setActiveTab("received")}
            />
            <Tab
              title="Converted"
              active={activeTab === "converted"}
              onClick={() => setActiveTab("converted")}
            />
          </Flex>
        )}
      </Flex>

      <Box
        display={"flex"}
        flexDirection={{ lg: another ? "row" : "column", sm: "column" }}
        justifyContent={
          another && filteredTransactions?.length > 0
            ? "space-evenly"
            : "center"
        }
        width={"100%"}
        alignItems={"center"}
      >
        {loading && !filteredTransactions ? (
          <Spinner size="lg" />
        ) : filteredTransactions?.length > 0 ? (
          <Transactions
            data={filteredTransactions}
            activeAccount={selectedAccount}
            another={another}
            accountSection={accountSection}
          />
        ) : (
          <Text>No Transactions found in {activeTab}</Text>
        )}
      </Box>

      {filteredTransactions?.length > (accountSection ? 2 : 3) && (
        <NavLink to={"/admin/transactions"}>
          <Button mt="10px" css={borderStyles("30px", false)} background={bg}>
            See More
          </Button>
        </NavLink>
      )}
    </Card>
  );
}
