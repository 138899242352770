/* eslint-disable */
import { BASE_URL } from "Baseurl";
import { useEffect, useState } from "react";
import apiInstance from "./interceptor";
import { useAccount } from "contexts/Context";
export const sendPayment = async (formData) => {
  try {
    const response = await apiInstance.post(
      `${BASE_URL}/payment/create`,
      formData
    );
    return response;
  } catch (error) {
    throw error.response.data.message;
  }
};
export const useGetHistory = () => {
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(null);
  const { account } = useAccount();

  useEffect(() => {
    const fetchHistory = async () => {
      setLoading(true);
      try {
        const response = await apiInstance.get(
          `${BASE_URL}/payment/list/transactions/${account}`
        );

        const res = response.data?.data?.rows?.filter(
          (transaction) =>
            transaction.sender.account_id === account ||
            transaction.receiver.account_id === account
        );
        setHistory(res);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching history:", error);
        setLoading(false);
      }
    };

    fetchHistory();
  }, [account]);

  return { history, loading }; // Return the state
};
