import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { getCompany } from "api";
import { assets } from "assets/img/layout/settings";
import { useEffect, useState } from "react";
import { borderStyles } from "theme/border";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import {
  Email,
  PhoneNumber,
  ResetPassword,
  TwoFactorAuthentication,
} from "./Popups";

const SettingsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [company, setCompany] = useState(null);
  const [selectedSetting, setSelectedSetting] = useState();
  const [small] = useMediaQuery("(max-width: 768px)");
  const user = JSON.parse(localStorage.getItem("user"));
  const warning = {
    status:
      user.emailAuthStatus === "PENDING" || user.appAuthStatus === "PENDING",
    message:
      (user.emailAuthStatus === "PENDING" ||
        user.appAuthStatus === "PENDING") &&
      `Please complete ${
        user.emailAuthStatus === "PENDING" ? "email" : "app"
      } 2fa settings`,
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const companyData = await getCompany();
        setCompany(companyData);
      } catch (error) {
        console.error("Failed to fetch company data", error);
      }
    };
    if (!company) {
      fetch();
    }
  }, [company]);

  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );

  const settings = [
    {
      id: 1,
      name: "Avatar",
      value: (
        <img
          src={assets.dp}
          style={{ height: "30px", width: "30px", borderRadius: "50%" }}
          alt="dp"
        />
      ),
      type: "Account Information",
      icon: assets?.avatar,
    },
    {
      id: 2,
      name: "Company Name",
      value: company ? (
        company.company_name
      ) : (
        <SkeletonTheme baseColor={"#646466"} highlightColor={"#a2a2a3"}>
          <Skeleton width={150} />
        </SkeletonTheme>
      ),
      type: "Account Information",
      icon: assets?.name,
    },
    {
      id: 3,
      name: "Company Registration Number",
      value: company ? (
        company.company_id
      ) : (
        <SkeletonTheme baseColor={"#646466"} highlightColor={"#a2a2a3"}>
          <Skeleton width={100} />
        </SkeletonTheme>
      ),
      type: "Account Information",
      icon: assets?.registration,
    },
    {
      id: 4,
      name: "Company Type",
      value: company ? (
        company.company_type
      ) : (
        <SkeletonTheme baseColor={"#646466"} highlightColor={"#a2a2a3"}>
          <Skeleton width={100} />
        </SkeletonTheme>
      ),
      type: "Account Information",
      icon: assets?.type,
    },
    {
      id: 5,
      name: "Company Address",
      value: company ? (
        company.company_address &&
        Object.entries(company.company_address || {})
          .filter(([key, value]) => key !== "address_id" && value)
          .map(([key, value]) => value)
          .join(", ")
      ) : (
        <SkeletonTheme baseColor={"#646466"} highlightColor={"#a2a2a3"}>
          <Skeleton width={250} />
        </SkeletonTheme>
      ),
      type: "Account Information",
      icon: assets?.address,
    },
    {
      id: 6,
      name: "Add/Change Email Address",
      value: company ? (
        company.company_email
      ) : (
        <SkeletonTheme baseColor={"#646466"} highlightColor={"#a2a2a3"}>
          <Skeleton width={150} />
        </SkeletonTheme>
      ),
      type: "Security",
      icon: assets?.email,
      editable: company?.company_email && true,
      key: "Email",
    },
    {
      id: 7,
      name: "Add/change phone number",
      value: company ? (
        company.company_phone_number
      ) : (
        <SkeletonTheme baseColor={"#646466"} highlightColor={"#a2a2a3"}>
          <Skeleton width={150} />
        </SkeletonTheme>
      ),
      type: "Security",
      icon: assets?.phone,
      editable: company?.company_phone_number && true,
      key: "Phone",
    },
    {
      id: 8,
      name: "Set/reset 2FA",
      value: "Last reset 365 days ago",
      icon: assets?.auth,
      type: "Security",
      editable: true,
      key: "2FA",
    },
    {
      id: 9,
      name: "Reset password",
      value: "Last reset 56 days ago",
      icon: assets?.reset,
      type: "Security",
      editable: true,
      key: "Reset",
    },
    {
      id: 10,
      name: "Deactivate Account",
      value: (
        <Button
          background={background}
          css={borderStyles(false, true)}
          width="190px"
        >
          Deactivate
        </Button>
      ),
      icon: assets?.deactivate,
      type: "Security",
    },
  ];

  const handleOpen = (modal) => {
    onOpen();
    setSelectedSetting(modal);
  };

  const handleClose = () => {
    onClose();
  };

  const renderSettingsByType = (type, id) => {
    return settings
      .filter((setting) => setting.type === type)
      .map((setting) =>
        !small ? (
          <Flex
            key={setting.id}
            display="flex"
            justify="space-between"
            alignItems={{ sm: "start", lg: "center" }}
            direction={{ sm: "column", lg: "row" }}
            mb={4}
            py="10px"
            width={{ sm: "95%", lg: "85%" }}
          >
            <Flex mr={4} justify={"center"} align={"center"}>
              {setting.icon}
              <Box fontWeight="bold" ml={{ sm: "10px", lg: "30px" }}>
                {setting.name}
              </Box>
            </Flex>
            <Flex justify={"end"} align={"center"} position={"relative"}>
              <Box>
                {setting.id === 8 && warning.status ? (
                  <Flex align={"center"}>
                    <Icon as={WarningIcon} color={"#edb009"} />
                    <span style={{ color: "#edb009", margin: "0px 5px" }}>
                      {warning.message}
                    </span>
                  </Flex>
                ) : (
                  <span>{setting.value}</span>
                )}
              </Box>
              {setting.editable && (
                <Box
                  right="-40px"
                  position={"absolute"}
                  cursor={"pointer"}
                  onClick={() => handleOpen(setting?.key)}
                >
                  {assets.pencil}
                </Box>
              )}
            </Flex>
          </Flex>
        ) : (
          <Flex
            key={setting.id}
            display="flex"
            justify="space-between"
            alignItems={{ sm: "center", lg: "center" }}
            mb={4}
            py="10px"
            width={{ sm: "95%", lg: "85%" }}
          >
            <Flex>
              <Flex mr={4} justify={"center"} align={"center"}>
                {setting.icon}
              </Flex>
              <Flex
                justify={"end"}
                align={"start"}
                direction={"column"}
                position={"relative"}
                ml={{ sm: "10px", lg: "30px" }}
              >
                <Box fontWeight="bold">{setting.name}</Box>
                <Box fontSize={{ sm: "12px" }}>{setting.value}</Box>
              </Flex>
            </Flex>
            {setting.editable && (
              <Box cursor={"pointer"} onClick={() => handleOpen(setting?.key)}>
                {assets.pencil}
              </Box>
            )}
          </Flex>
        )
      );
  };

  return (
    <Box
      mt={{ lg: "12vh", sm: "8vh" }}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      width={"100%"}
    >
      {settings.map((setting, index) => {
        if (index === 0 || settings[index - 1].type !== setting.type) {
          return (
            <Box
              key={setting.type}
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Box
                fontWeight="bold"
                mb={"20px"}
                width={{ lg: "85%", sm: "95%" }}
                fontSize={"20.06px"}
                display={"flex"}
                justifyContent={"start"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                {setting.type}
              </Box>
              {renderSettingsByType(setting.type, setting.id)}
              {index === 0 && (
                <Box borderBottom="1px solid #505050" my="25px" width="85%" />
              )}
            </Box>
          );
        }
        return null;
      })}
      <Email
        isOpen={isOpen && selectedSetting === "Email"}
        onClose={handleClose}
        oldEmail={company?.company_email}
        companyId={company?.company_id}
      />
      <PhoneNumber
        isOpen={isOpen && selectedSetting === "Phone"}
        onClose={handleClose}
        oldPhone={company?.company_phone_number}
      />
      <TwoFactorAuthentication
        isOpen={isOpen && selectedSetting === "2FA"}
        onClose={handleClose}
        email={company?.company_email}
      />
      <ResetPassword
        isOpen={isOpen && selectedSetting === "Reset"}
        onClose={handleClose}
      />
    </Box>
  );
};

export default SettingsPage;
