/* eslint-disable */
import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdLock,
  MdAddCard,
  MdDoubleArrow,
  MdDashboard,
  MdHistory,
  MdPeople,
  MdSettings,
  MdLogout,
} from "react-icons/md";

import MainDashboard from "views/admin/default";
import Accounts from "views/admin/accounts";
import Payments from "views/admin/payments";
import Cards from "views/admin/cards";
import Transactions from "views/admin/transactions";
import SignInCentered from "views/auth/signIn";
import SignUpCentered from "views/auth/signup";
import ForgetPassword from "views/auth/forget";
import ResetPassword from "views/auth/reset";
import VerifyOtp from "views/auth/otp";
import { VerifyEmail, Verify } from "views/verification";
import Sumsub from "views/kyb";
import BeneficiaryForm from "views/admin/benefeciaries";
import SettingsPage from "views/admin/settings";
import { logout } from "api";
const LogoutComponent = () => {
  React.useEffect(() => {
    logout();
  }, []);

  // This component doesn't render anything, as it's just used for its side effect
  return null;
};

export const adminRoutes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
    compType: "Home",
  },
  // {
  //   name: "Account",
  //   layout: "/admin",
  //   path: "/account",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  //   compType: "Home",

  // },
  {
    name: "Accounts",
    layout: "/admin",
    path: "/accounts",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Accounts,
    compType: "Home",
  },
  {
    name: "Cards",
    layout: "/admin",
    icon: <Icon as={MdAddCard} width="20px" height="20px" color="inherit" />,
    path: "/cards",
    component: Cards,
    compType: "Home",
  },
  // {
  //   name: "Merchents",
  //   layout: "/admin",
  //   path: "/merchents",
  //   icon: <Icon as={MdBusiness} width="20px" height="20px" color="inherit" />,
  //   component: Void,
  //   compType: "Home",
  // },
  {
    name: "Transfers",
    layout: "/admin",
    path: "/transfers",
    icon: (
      <Icon as={MdDoubleArrow} width="20px" height="20px" color="inherit" />
    ),
    component: Payments,
    compType: "Payments",
  },
  {
    name: "Transactions",
    layout: "/admin",
    path: "/transactions",
    icon: <Icon as={MdHistory} width="20px" height="20px" color="inherit" />,
    component: Transactions,
    compType: "Payments",
  },
  {
    name: "Beneficiaries",
    layout: "/admin",
    path: "/benefaciaries",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    component: BeneficiaryForm,
    compType: "Payments",
  },
  // {
  //   name: "Crypto",
  //   layout: "/admin",
  //   path: "/crypto",
  //   icon: (
  //     <Icon as={MdCurrencyBitcoin} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: Void,
  //   compType: "Payments",
  // },
  // {
  //   name: "Linked",
  //   layout: "/admin",
  //   path: "/linked",
  //   icon: (
  //     <Icon as={MdDatasetLinked} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: Void,
  //   compType: "Hub",
  // },
  // {
  //   name: "Expenses",
  //   layout: "/admin",
  //   path: "/expensis",
  //   icon: <Icon as={MdMoney} width="20px" height="20px" color="inherit" />,
  //   component: Void,
  //   compType: "Hub",

  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    component: SettingsPage,
    compType: "Controls",
  },
  {
    name: "Logout",
    layout: "/admin",
    path: "/logout",
    icon: <Icon as={MdLogout} width="20px" height="20px" color="inherit" />,
    component: LogoutComponent,
    compType: "Controls",
  },
];
export const authRoutes = [
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignUpCentered,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgetPassword,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ResetPassword,
  },
  {
    name: "Verify OTP",
    layout: "/auth",
    path: "/verify-otp",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: VerifyOtp,
  },
];
export const verifyRoutes = [
  {
    name: "Email verification",
    layout: "/verification",
    path: "/email-verification",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: VerifyEmail,
  },
  {
    name: "Verify-Email",
    layout: "/verification",
    path: "/verify-email",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Verify,
  },
];
export const kybRoutes = [
  {
    name: "Sumsub verification",
    layout: "/kyb",
    path: "/verification",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Sumsub,
  },
];
