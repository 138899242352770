import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'


export default function TermsDropdown() {
  const items = [
    {
      key: 'BusinessEEA',
      label: 'Coincierge EEA Business Card Terms',
      pdf: '/businessEEA.pdf',
    },
    {
      key: 'Business',
      label: 'Coincierge Business Card Terms',
      pdf: '/business.pdf',
    },
    {
      key: 'ConsumerEEA',
      label: 'Coincierge EEA Consumer Card Terms',
      pdf: '/consumerEEA.pdf',
    },
    {
      key: 'Consumer',
      label: 'Coincierge Consumer Card Terms',
      pdf: '/consumer.pdf',
    },
  ]

  return (
    <Menu placement='bottom'>
      <MenuButton as={Button} variant='ghost' fontSize={14} color="#898989">
        Terms & Conditions
      </MenuButton>
      <MenuList background="#101010">
      {items.map((item) => (
          <MenuItem key={item.key}>
            <a href={item.pdf} style={{zIndex: 50, color: "#fefefe", fontSize: 12}}>{item.label}</a>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
