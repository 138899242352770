import { BASE_URL } from "Baseurl";
import axios from "axios";
import queryString from "query-string";
import apiInstance from "./interceptor";

// Function to handle registering a user
export const register = async (userData, toast) => {
  try {
    const response = await axios.post(`${BASE_URL}/company/register`, userData);

    return response.data;
  } catch (error) {
    toast({
      title: error.response.data.message,
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top",
    });
    throw error.response.data.message;
  }
};

export const login = async (credentials) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, credentials);
    localStorage.setItem("tokens", JSON.stringify(response.data.tokens));
    localStorage.setItem("user", JSON.stringify(response.data.user));
    return response.data;
  } catch (error) {
    if (error?.response?.data?.code === 400) {
      if (JSON.parse(error.response.data.message).emailAuthStatus) {
        try {
          await axios.post(`${BASE_URL}/auth/send-email-otp`, credentials);
        } catch (error) {}
      }
      localStorage.setItem("authFlows", error.response.data.message);
      localStorage.setItem("email", credentials.email);
      window.location.reload();
    } else {
      throw error;
    }
  }
};
export const verifyOtp = async (otp, type, notreload, emailSent) => {
  let auth =
    localStorage.getItem("authFlows") &&
    JSON.parse(localStorage.getItem("authFlows"));
  let email = localStorage.getItem("email");
  try {
    const response = await axios.post(
      `${BASE_URL}/auth/${
        type === "emailAuthStatus" ? "verify-otp" : "verify-app-otp"
      }`,
      type === "emailAuthStatus"
        ? { otp }
        : { email: notreload ? emailSent : email, totp: otp }
    );
    if (
      type === "emailAuthStatus" &&
      auth?.appAuthStatus === "ENABLED" &&
      !notreload
    ) {
      auth.emailAuthStatus = false;
      localStorage.setItem("authFlows", JSON.parse(auth));
      window.location.reload();
    } else if (
      type === "appAuthStatus" &&
      auth?.emailAuthStatus === "ENABLED" &&
      !notreload
    ) {
      auth.emailAuthStatus = false;
      localStorage.setItem("authFlows", JSON.parse(auth));

      window.location.reload();
    } else {
      localStorage.removeItem("authFlows");
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      if (response.data.tokens) {
        localStorage.setItem("tokens", JSON.stringify(response?.data?.tokens));
      }
      window.location.reload();
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateOtp = async (payload) => {
  let user = JSON.parse(localStorage.getItem("user"));
  let tokens = JSON.parse(localStorage.getItem("tokens"));
  try {
    const response = await axios.put(
      `${BASE_URL}/auth/auth-setting`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${tokens.access.token}`,
        },
      }
    );
    if (payload.isEmailAuthEnabled !== undefined) {
      user.emailAuthStatus =
        user.emailAuthStatus === "DISABLED"
          ? "PENDING"
          : user.emailAuthStatus === "PENDING"
          ? "ENABLED"
          : "DISABLED";
    }
    if (payload.isAppAuthEnabled !== undefined) {
      user.appAuthStatus =
        user.appAuthStatus === "DISABLED"
          ? "PENDING"
          : user.appAuthStatus === "PENDING"
          ? "ENABLED"
          : "DISABLED";
    }

    localStorage.setItem("user", JSON.stringify(user));
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const requestQRCODE = async () => {
  try {
    const response = await apiInstance.post(`${BASE_URL}/auth/request/qr`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getCompany = async () => {
  const user = localStorage.getItem("user");
  if (user) {
    try {
      const response = await apiInstance.get(
        `${BASE_URL}/company/id?companyId=${JSON.parse(user).id}`
      );
      return response.data;
    } catch (error) {}
  }
};
export const updateCompany = async (companyId, data) => {
  try {
    const response = await apiInstance.patch(`${BASE_URL}/company/id`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTokenKYB = async (data) => {
  const user = localStorage.getItem("user");
  let query = queryString.stringify(data);
  if (user) {
    try {
      const response = await apiInstance.get(
        `${BASE_URL}/company/kyb?${query}`
      );
      return response.data;
    } catch (error) {}
  }
};

// Function to handle user logout
export const logout = async () => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  try {
    const response = await axios.post(`${BASE_URL}/auth/logout`, {
      refreshToken: tokens.refresh.token,
    });
    localStorage.removeItem("user");
    localStorage.removeItem("tokens");
    window.location.reload();
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to handle forgot password
export const forgotPassword = async (emailData) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/auth/forgot-password`,
      emailData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to handle resetting password
export const resetPassword = async (passwordData, token, history) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/auth/reset-password?token=${token}`,
      passwordData
    );
    history.push("/auth/login");
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const resetPasswordWhenLoggedIn = async (passwordData) => {
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  if (!tokens) throw new Error({ message: "Invalid Token" });

  try {
    const response = await apiInstance.put(
      `${BASE_URL}/auth/change-password`,
      passwordData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to send verification email
export const sendVerificationEmail = async () => {
  try {
    const response = await apiInstance.post(
      `${BASE_URL}/auth/send-verification-email`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Function to verify email
export const verifyEmail = async (verificationData) => {
  try {
    const response = await apiInstance.post(
      `${BASE_URL}/auth/verify-email?token=${verificationData}`
    );
    let user = JSON.parse(localStorage.getItem("user"));
    user.isEmailVerified = true;
    localStorage.setItem("user", JSON.stringify(user));

    return response.data;
  } catch (error) {
    throw error;
  }
};
