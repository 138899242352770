// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components
import Card from "components/card/Card.js";
import LineChart from "components/charts/BarChart";

import { MdOutlineCalendarToday } from "react-icons/md";
import { borderStyles } from "theme/border";

// Assets

export default function TotalSpent(props) {
  const { selectedAccount, accounts, setAccount, ...rest } = props;

  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  const getValue = (id, val) => {
    return id === "all"
      ? accounts?.reduce((sum, account) => sum + account.balance, 0)
      : accounts?.find((account) => Number(id) === account.id)?.[val];
  };

  return (
    <Card
      justifyContent="start"
      align="center"
      direction="column"
      w="100%"
      mb="0px"
      bg={boxBg}
      {...rest}
      css={borderStyles()}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px" align>
        <Flex align="center" justify={"space-between"} w="100%">
          <Flex
            bg={boxBg}
            justify={"space-between"}
            align={"center"}
            color={textColorSecondary}
            width={{ lg: "40%", sm: "60%" }}
            borderRadius="7px"
            fontSize={"10px"}
            css={borderStyles("10px", true)}
          >
            <Icon
              as={MdOutlineCalendarToday}
              color={textColorSecondary}
              me="4px"
              width={"20px"}
              height={"20px"}
              ml="8px"
            />
            <Select
              width={{ sm: "100%", lg: "80%" }}
              style={{
                backgroundColor: boxBg,
                color: "white",
                fontSize: "15px",
              }}
            >
              <option
                value="1"
                style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
              >
                Monthly
              </option>
              <option
                value="2"
                style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
              >
                Weekly
              </option>
              <option
                value="2"
                style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
              >
                Daily
              </option>
            </Select>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        w="100%"
        flexDirection={{ base: "column", lg: "row", sm: "row" }}
        justify="space-between"
        paddingX={"30px"}
        pt={"30px"}
      >
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={"gray"}
            fontSize="18px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
            display="flex"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Flex
              width="10px"
              height="10px"
              background="gray"
              sx={{ borderRadius: "50%", marginRight: "5px" }}
            ></Flex>
            Sent
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="white"
              fontSize={{ lg: "3xl", sm: "lg" }}
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              {getValue(selectedAccount, "balance")}{" "}
              {getValue(selectedAccount, "currency")}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={"gray"}
            fontSize="18px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
            display="flex"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Flex
              width="10px"
              height="10px"
              background="white"
              sx={{ borderRadius: "50%", marginRight: "5px" }}
            ></Flex>
            Receive
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="white"
              fontSize={{ lg: "3xl", sm: "lg" }}
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              {getValue(selectedAccount, "balance")}{" "}
              {getValue(selectedAccount, "currency")}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box minW="100%" minH="280px" mt="auto">
        <LineChart />
      </Box>
    </Card>
  );
}
