
const signupValidation = (data) => {
  const errors = {};

  Object.keys(data).forEach((key) => {
    const field = key.replace(/_/g, " ");
    const value = data[key];

    // Check if the field is empty
    if (!value || value.trim() === "") {
      errors[key] = `${field} is required`;
    }
    
    // Additional custom validation for email
    if (key === "company_email" && !/^\S+@\S+\.\S+$/.test(value)) {
      errors[key] = `Valid ${field.toLowerCase()} is required`;
    }
    if (
      key === "company_phone_number" &&
      !/^\+(?:[0-9] ?){6,14}[0-9]$/.test(value)
    ) {
      errors[key] = `Valid ${field.toLowerCase()} is required`;
    }

    // Additional custom validation for password
    if (key === "password" && value.length < 8) {
      errors[key] = `${field} must be at least 8 characters`;
    }

    // Additional custom validation for confirm password
    if (key === "confirm_password" && value !== data.password) {
      errors[key] = `Passwords do not match`;
    }
  });

  // If there are no errors, return null
  if (Object.keys(errors).length === 0) {
    return null;
  }

  // Otherwise, return the errors object
  return errors;
};

const loginValidation = (data) => {
  const errors = {};

  // Check if email is provided and valid
  if (!data.email || !/^\S+@\S+\.\S+$/.test(data.email)) {
    errors.email = "Valid email is required";
  }

  // Check if password is provided and meets the length requirement
  if (!data.password || data.password.length < 8) {
    errors.password = "Password should be at least 8 characters";
  }

  // If there are no errors, return null
  if (Object.keys(errors).length === 0) {
    return null;
  }

  // Otherwise, return the errors object
  return errors;
};
const verifyEmailValidation = (data) => {
  const errors = {};

  // Check if email is provided and valid
  if (!data.email || !/^\S+@\S+\.\S+$/.test(data.email)) {
    errors.email = "Valid email is required";
  }

  // If there are no errors, return null
  if (Object.keys(errors).length === 0) {
    return null;
  }

  // Otherwise, return the errors object
  return errors;
};

const validatePayment = (formData) => {
  let errors = {}; // Initialize the errors object


  if (!formData.account_id) {
    errors.account_id = "Account ID is required.";
  } else if (!Number.isInteger(parseInt(formData.account_id))) {
    errors.account_id = "Account ID must be an integer.";
  }

  if (!formData.amount) {
    errors.amount = "Amount is required.";
  } else if (isNaN(formData.amount)) {
    errors.amount = "Amount must be a number.";
  }
  if (!formData.currency) {
    errors.currency = "Currency is required.";
  }
  if (!formData.reason) {
    errors.reason = "Payment reason is required.";
  }

  if (formData.receiver.account_id && !formData.currency.trim()) {
    errors.currency =
      "Currency is required when receiver account ID is provided.";
  } else if (formData.currency.length > 4) {
    errors.currency = "Currency must be at most 4 characters long.";
  }

  if (!formData.receiver.account_id) {
    errors.receiver_account_id = "Receiver account ID is required.";
  } else if (!Number.isInteger(formData.receiver.account_id)) {
    errors.receiver_account_id = "Receiver account ID must be an integer.";
  }
  
  return errors;
};

const cardValidation = (formData) => {
  const errors = {};

  // Validate Emboss Name
  if (!formData.emboss_name.trim()) {
    errors.emboss_name = "Emboss Name is required";
  }

  // Validate Account Selection
  if (!formData.account_id) {
    errors.account_id = "Please select an account";
  }

  // Return validation errors
  return errors;
};
const validateAccount = (account) => {
  const errors = {};

  if (!account.name) {
    errors.name = "Account name is required";
  }

  if (!account.type) {
    errors.type = "Account type is required";
  }

  if (!account.currency) {
    errors.currency = "Currency is required";
  }

  return errors;
};

export {
  cardValidation, loginValidation, signupValidation, validateAccount, validatePayment, verifyEmailValidation
};

