/* eslint-disable */
import { useEffect, useState } from "react";
import { getCompany } from "./auth";

export const useCheckAuth = () => {
  const [company, setCompany] = useState(null);
  const companyFetch = async () => {
    const response = await getCompany();
    setCompany(response);
  };
  useEffect(() => {
    if (!company) {
      companyFetch();
    }
  }, []);

  let user = localStorage.getItem("user");
  let authFlows = localStorage.getItem("authFlows");
  let tokens = localStorage.getItem("tokens");
  if (user && tokens && (authFlows === null || authFlows === undefined)) {
    user = JSON.parse(user);
    tokens = JSON.parse(tokens);
    if (company === null) {
      return null;
    } else {
      return company
        ? {
            isAuth: user && tokens && true,
            user: user,
            token: tokens,
            authFlows: false,
            isVerified: user.isEmailVerified,
            isKyb: company.company_status === "ACTIVE" ? true : true,
          }
        : {
            isAuth: false,
            user: false,
            token: false,
            authFlows: false,
            otp: false,
            isVerified: false,
            isKyb: false,
          };
    }
  } else {
    if (authFlows) {
      authFlows = authFlows !== undefined && JSON.parse(authFlows);
      return {
        isAuth: false,
        user: false,
        token: false,
        authFlows: authFlows,
        isVerified: false,
        isKyb: false,
      };
    } else {
      return {
        isAuth: false,
        user: false,
        token: false,
        authFlows: false,
        otp: false,
        isVerified: false,
        isKyb: false,
      };
    }
  }
};
