/* eslint-disable */
import { useEffect, useState } from "react";
import { Button, Flex, Spinner } from "@chakra-ui/react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { getCompany, getTokenKYB, logout } from "api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Sumsub() {
  const [token, setToken] = useState();
  const history = useHistory();

  const getData = async (id) => {
    const { user } = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const response = await getCompany(user?.id);
      if (response?.company_status !== "ACTIVE") {
        const resp = await getTokenKYB({
          companyId: response.company_id,
          companyUUId: response.company_uuid,
        });
        if (resp?.data?.token) {
          setToken(resp?.data.token);
        }
      } else {
        history.push("/admin/default");
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {}, [token]);

  return (
    <Flex
      maxW={{ base: "100%", md: "100%" }}
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      alignItems="center"
      minH={"100vh"}
      justifyContent="center"
      mb={{ base: "30px", md: "10px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "0vh" }}
      padding="40px"
      flexDirection="column"
    >
      {token ? (
        <>
          <SumsubWebSdk accessToken={token} expirationHandler={getData} />
          <Button onClick={logout}>Logout</Button>
        </>
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      )}
    </Flex>
  );
}

export default Sumsub;
