import React, { useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { resetPassword } from "api/auth";
import illustration from "assets/img/auth/auth.png";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

function Reset() {
  // Chakra color mode

  const textColor = useColorModeValue("navy.700", "white");
  const history = useHistory();
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const background = useColorModeValue("whiteAlpha.900", "whiteAlpha.100");

  const toast = useToast();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [errors, setErrors] = useState({});

  const [data, setData] = useState({
    password: "",
  });

  const updateData = (feild, value) => {
    setData((prev) => ({ ...prev, [feild]: value }));
    setErrors((prev) => ({ ...prev, [feild]: "" }));
  };

  const handleSubmit = async () => {
    // Check if password is provided and meets the length requirement
    if (data.password.length < 8) {
      setErrors({
        password: "password should be at least 8 characters",
      });
      return;
    } else {
      try {
        await resetPassword(data, token, history);

        setData({
          email: "",
        });
      } catch (error) {
        console.error("Error occurred:", error);
        toast({
          title: "Invalid email",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your new password!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl isInvalid={errors.email} mb="24px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              New Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="new password"
              fontWeight="500"
              value={data.password}
              onChange={(e) => updateData("password", e.target.value)}
              size="lg"
            />
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>

          <Button
            fontSize="sm"
            background={background}
            fontWeight="500"
            w="100%"
            h="50"
            mb="24px"
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Reset;
