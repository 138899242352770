/* eslint-disable */
import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
];
const dat = Array.from({ length: 8 }).map(() => Math.random(0, 1000));
const dat2 = Array.from({ length: 8 }).map(() => Math.random(0, 1000));

export const data = {
  labels,
  datasets: [
    {
      data: dat, // Random data between 0 and 1000
      backgroundColor: "gray", // Dynamically set colors based on data
      label: "",
    },
    {
      data: dat2, // Random data between 0 and 1000
      backgroundColor: "white", // Dynamically set colors based on data
      label: "",
    },
  ],
};

export const options = {
  maintainAspectRatio: false, // Disable zooming on hover
  plugins: {
    legend: {
      display: false, // Hide legend
      width: "100%",
    },
    title: {
      display: true,
    },
  },
  scales: {
    x: {
      type: "category",
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

function LineChart() {
  return <Bar options={options} data={data} />;
}

export default LineChart;
