import axios from "axios";

// Add a request interceptor to attach the access token to each request
axios.interceptors.request.use(
  async (config) => {
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (tokens && tokens.access.token) {
      config.headers.Authorization = `Bearer ${tokens.access.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration and refresh the token
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const tokens = JSON.parse(localStorage.getItem("tokens"));
    if (error.response.status === 401 && !originalRequest._retry && tokens) {
      originalRequest._retry = true;
      try {
        // localStorage.removeItem("user");
        // localStorage.removeItem("tokens");
        // window.location.reload();

        return axios(originalRequest);
      } catch (refreshError) {
        // Handle refresh token error, e.g., redirect to login
        console.error("Refresh token error:", refreshError);
        // For simplicity, you may want to redirect to login page if refresh token fails
        // Example: window.location.href = '/auth/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
