/* eslint-disable */

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { activateCard, orderNewCard, resetPin } from "api";
import { useAccount } from "contexts/Context";
import Pagination from "contexts/Pagination";
import { useEffect, useState } from "react";
import { IoMdFlash, IoMdKey } from "react-icons/io";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-phone-input-2/lib/style.css";
import { borderStyles } from "theme/border";
import { cardValidation } from "validations";
import Card from "./Card";

const BankCards = ({}) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    account_id: "",
    phone_number: "+923146643435",
    type: 1,
    emboss_name: "",
  });

  const {
    accounts,
    cards: data,
    setRefreshAccounts,
    account,
    refreshAccounts,
    changeAccount,
  } = useAccount();
  const [, setActiveAccount] = useState(accounts && accounts[0].id);

  const text = useColorModeValue("black", "white");
  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  const cardBack = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );

  const toast = useToast();
  const [errors, setErrors] = useState();
  const [paginatedData, setPaginatedData] = useState();
  useEffect(() => {
    console.log("called");
    setPaginatedData(data?.filter((card) => account === card.account_id));
  }, [data, account]);
  console.log(paginatedData, "paginateddata");

  const resetCardPin = async (cardId) => {
    const response = await resetPin(cardId);
    toast({
      title: "Pin has been reset",
      status: "success",
      duration: 2000,
      position: "top",
    });
  };

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setErrors({
      ...errors,
      [e.target.name]: "",
    });
  };
  const activate = async (card_number) => {
    try {
      await activateCard(card_number);
      toast({
        position: "top",
        title: "Card activated Successfully",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Card activation failed",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [accountsPerPage] = useState(4);
  const indexOfLastAccount = currentPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
  let currentData = paginatedData?.slice(
    indexOfFirstAccount,
    indexOfLastAccount
  );

  const handleCardSelect = (card) => {
    setSelectedCard(card);
  };

  const modalBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)"
  );

  const handleSubmit = async () => {
    let payload = formData;
    const errors = cardValidation(payload);

    // If there are validation errors, set them and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    payload.phone_number = "+923145566789";
    await orderNewCard(payload);
    setFormData((prev) => ({ ...prev, ["type"]: 1, ["emboss_name"]: "" }));
    setRefreshAccounts(!refreshAccounts);
    setShowModal(false);
  };

  useEffect(() => {
    accounts && accounts.length > 0 && setActiveAccount(accounts[0].id);
    setRefreshAccounts(true);
  }, [accounts]);
  useEffect(() => {}, [data]);
  return (
    <Flex
      direction="row"
      justify="space-between"
      flexWrap={"wrap"}
      mt={{ sm: "6vh", lg: !data ? "12vh" : "12vh" }}
    >
      {!data && !currentData ? (
        <SkeletonTheme
          baseColor={"#646466"}
          highlightColor={"#a2a2a3"}
          width={"100%"}
        >
          <Skeleton borderRadius={30} width="79vw" height="80vh" />
        </SkeletonTheme>
      ) : (
        <Flex
          width={{ lg: "100%", sm: "100%" }}
          justify="space-between"
          overflowY={{ lg: "hidden", sm: "auto" }}
          wrap={{ sm: "wrap-reverse", lg: "nowrap" }}
          // background={background}
          // css={borderStyles()}
        >
          <Flex
            justifyContent={"start"}
            flexDirection={"column"}
            alignItems={"center"}
            width={{ lg: "48%", sm: "100%" }}
            padding={"30px"}
            borderRadius={"30px"}
            background={background}
            mt={{ sm: "5vh", lg: "0" }}
            height={{ lg: "80vh", sm: "85vh" }}
            position={"relative"}
            css={borderStyles()}
          >
            <Flex
              mb={4}
              justify={"space-between"}
              direction={{ lg: "row", sm: "column" }}
              width={"100%"}
              alignItems={"center"}
            >
              <Select
                onChange={(e) => {
                  changeAccount(e.target.value);
                  setSelectedCard(null);
                  currentData = undefined;
                  setCurrentPage(1);
                }}
                background={background}
                value={account}
                width={{ lg: "39%", sm: "100%" }}
                css={borderStyles(false, false)}
              >
                {accounts?.map((account) => (
                  <option
                    value={account.id}
                    style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
                  >
                    {account.name}
                  </option>
                ))}
              </Select>
              <Button
                background={background}
                css={borderStyles(false, true)}
                // padding={"10px 20px"}
                my={{ sm: "10px" }}
                width={{ lg: "39%", sm: "100%" }}
                onClick={() => {
                  setShowModal(true);
                  setFormData({
                    account_id: "",
                    phone_number: "",
                    type: 1,
                    emboss_name: "",
                  });
                  setErrors(false);
                }}
                fontSize={{ lg: "20px", sm: "15px" }}
              >
                Add Cards
              </Button>
            </Flex>

            <Flex
              width={"100%"}
              height={"80%"}
              justify={currentData?.length === 0 ? "center" : "start"}
              alignItems={"center"}
              flexDirection={"column"}
              pb={{ sm: "10vh" }}
            >
              {currentData === undefined ? (
                <Spinner />
              ) : currentData?.length > 0 ? (
                currentData?.map((card) => (
                  <Box
                    key={card.id}
                    w={"100%"}
                    onClick={() => handleCardSelect(card)}
                    background={cardBack}
                    padding="10px 20px"
                    margin="10px 0px"
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"start"}
                    flexDirection={"column"}
                    borderRadius={"10px"}
                    height={"12vh"}
                    cursor={"pointer"}
                    css={borderStyles("10px", false)}
                  >
                    <Text fontSize={{ lg: "2xl", sm: "sm" }}>
                      {card.emboss_name
                        .concat("  -  ")
                        .concat(card.type === 2 ? "Virtual" : "Standard")}
                    </Text>
                    <Text fontSize={{ lg: "xl", sm: "sm" }}>
                      {card.pan_mask}
                    </Text>
                  </Box>
                ))
              ) : (
                "No cards Associated with this account"
              )}
            </Flex>
            <Flex
              justify={"center"}
              width={{ lg: "100%", sm: "100%" }}
              mt={5}
              position={"absolute"}
              left={"0%"}
              bottom={"5%"}
            >
              <Pagination
                currentPage={currentPage}
                paginate={setCurrentPage}
                itemsPerPage={accountsPerPage}
                totalItems={paginatedData?.length}
              />
            </Flex>
          </Flex>

          <Flex
            direction="column"
            align="center"
            width={{ "4xl": "48%", "2xl": "48%", lg: "48%", sm: "100%" }}
          >
            {selectedCard && (
              <Flex
                px="30px"
                direction={"column"}
                width={"100%"}
                background={background}
                height={{ lg: "80vh", sm: "auto" }}
                css={borderStyles()}
              >
                <Flex
                  justify={"space-between"}
                  align={{ lg: "space-between" }}
                  direction={{ lg: "row", sm: "column" }}
                >
                  <Card card={selectedCard} />
                </Flex>
                <Flex
                  justify={"space-evenly"}
                  align={"center"}
                  direction={{ lg: "row", sm: "row" }}
                  width={{ sm: "100%", lg: "100%" }}
                  my={{ sm: "20px" }}
                  mx={{ lg: "3px" }}
                  borderRadius={"10px"}
                >
                  <Text
                    display={"flex"}
                    cursor={"pointer"}
                    fontSize={"10px"}
                    flexDirection="column"
                    alignItems={"center"}
                    onClick={() => resetCardPin(selectedCard.id)}
                  >
                    <Icon
                      as={IoMdKey}
                      width={"50px"}
                      height={"50px"}
                      background={cardBack}
                      borderRadius={"50%"}
                      padding={"2px"}
                      color={text}
                    />
                    Change Pin
                  </Text>
                  {selectedCard.card_status !== "ACTIVE" && (
                    <Text
                      display={"flex"}
                      fontSize={"10px"}
                      flexDirection="column"
                      alignItems={"center"}
                      cursor={"pointer"}
                      onClick={() => activate(selectedCard.id)}
                    >
                      <Icon
                        as={IoMdFlash}
                        width={"50px"}
                        height={"50px"}
                        background={cardBack}
                        borderRadius={"50%"}
                        padding={"2px"}
                        color={text}
                      />
                      Activate Card
                    </Text>
                  )}
                </Flex>
                <Text my="10px" fontSize={"lg"}>
                  Recent Transactions
                </Text>
                <Text textAlign={"center"} py={"50px"}>
                  No Transaction found
                </Text>
              </Flex>
            )}
          </Flex>

          <>
            {showModal && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backdropFilter: "blur(4px)",
                  zIndex: 1000, // Adjust this value as needed
                }}
              />
            )}
            <Modal
              isOpen={showModal}
              onClose={() => setShowModal(false)}
              isCentered
            >
              <ModalOverlay />
              <ModalContent
                width={{ base: "100%", sm: "80%" }}
                background={modalBg}
                border={"1px solid gray"}
              >
                <ModalHeader>Add Card</ModalHeader>
                <ModalBody>
                  <FormControl mb={4} isInvalid={errors && errors.emboss_name}>
                    <FormLabel>Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Enter Card Name"
                      value={formData.emboss_name}
                      name="emboss_name"
                      onChange={handleChange}
                      color={text}
                    />
                    {errors && errors.emboss_name && (
                      <FormErrorMessage>{errors.emboss_name}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl mb={4} isInvalid={errors && errors.account_id}>
                    <FormLabel>Account</FormLabel>
                    <Select
                      placeholder="Select Account"
                      name="account_id"
                      onChange={handleChange}
                      value={formData.account_id}
                    >
                      {accounts?.map((account) => (
                        <option
                          key={account.id}
                          style={{
                            background: "rgba(0,0,0,0.9)",
                            color: "white",
                          }}
                          color={text}
                          value={account.id}
                        >
                          {account.name}
                        </option>
                      ))}
                    </Select>
                    {errors && errors.account_id && (
                      <FormErrorMessage>{errors.account_id}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl mb={4} isInvalid={errors && errors.account_id}>
                    <FormLabel>Card Type</FormLabel>
                    <Select
                      name="type"
                      onChange={handleChange}
                      value={formData.type}
                    >
                      <option
                        key={1}
                        color={text}
                        value={1}
                        style={{
                          background: "rgba(0,0,0,0.9)",
                          color: "white",
                        }}
                      >
                        {"PHYSICAL"}
                      </option>
                      <option
                        key={2}
                        color={text}
                        value={2}
                        style={{
                          background: "rgba(0,0,0,0.9)",
                          color: "white",
                        }}
                      >
                        {"VIRTUAL"}
                      </option>
                    </Select>
                    {errors && errors.account_id && (
                      <FormErrorMessage>{errors.account_id}</FormErrorMessage>
                    )}
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={handleSubmit}
                    background={background}
                    css={borderStyles("30px", true)}
                  >
                    Submit
                  </Button>
                  <Button
                    onClick={() => setShowModal(false)}
                    ml={4}
                    background={background}
                    css={borderStyles("30px", true)}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </>
        </Flex>
      )}
    </Flex>
  );
};

export default BankCards;
