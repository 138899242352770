// Import necessary components and hooks
import {
  Box,
  Flex,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue
} from "@chakra-ui/react";
import { useGetHistory } from "api";
import { useAccount } from "contexts/Context";
import Pagination from "contexts/Pagination";
import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { borderStyles } from "theme/border";
import Transactions from "./transactions";

const TransactionDetails = ({ activeTransaction, setActiveTransaction }) => {
  const { sender, receiver, amount, converted_currency, payment_date } =
    activeTransaction;
  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  const date = new Date(payment_date);

  const formattedDate = date?.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });

  const formattedTime = date?.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return (
    activeTransaction && (
      <Flex
        width={{ lg: "48%", sm: "100%" }}
        flexWrap={"wrap"}
        justify="space-between"
        alignItems={"start"}
        borderRadius={"20px"}
        height={{ lg: "70vh", sm: "auto" }}
        p={3}
        css={borderStyles()}
        background={background}
      >
        <Box
          width={{ base: "100%", md: "100%" }}
          p={4}
          mb={{ lg: 4, sm: 2 }}
          height={{ sm: "auto", lg: "80%" }}
          borderRadius={10}
          display={"flex"}
          flexDirection={"column"}
        >
          <Text fontSize={"24px"} display={"flex"} alignItems={"center"}>
            Transaction Details
          </Text>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td>Sender</Td>
                <Td>{sender.name}</Td>
              </Tr>
              <Tr>
                <Td>Sender Id</Td>
                <Td>{sender.account_id}</Td>
              </Tr>
              <Tr>
                <Td>Receiver</Td>
                <Td>{receiver.name}</Td>
              </Tr>
              <Tr>
                <Td>Receiver Id</Td>
                <Td>{receiver.account_id}</Td>
              </Tr>
              <Tr>
                <Td>Amount</Td>
                <Td>
                  {amount} {converted_currency}
                </Td>
              </Tr>
              <Tr>
                <Td>Time</Td>
                <Td>{formattedDateTime}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Flex>
    )
  );
};

const Account = () => {
  const { changeAccount: setSelectedAccount, accounts } = useAccount();
  const [currentPage, setCurrentPage] = useState(1);
  const [accountsPerPage] = useState(4);
  const [activeAccount, setActiveAccount] = useState(Number(accounts?.[0].id));
  const { history, loading } = useGetHistory(
    activeAccount ? activeAccount : Number(accounts?.[0]?.id)
  );
  const [activeTransaction, setActiveTransaction] = useState(null);

  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  useEffect(() => {}, [activeAccount]);
  
  function filterTransactions() {
    let accounttoCheck = activeAccount
      ? activeAccount
      : Number(accounts?.[0]?.id);

    return history?.filter(
      (transaction) =>
        transaction.sender.account_id === accounttoCheck ||
        transaction.receiver.account_id === accounttoCheck
    );
  }

  const filteredTransactions = filterTransactions();
  const indexOfLastAccount = currentPage * accountsPerPage;
  const indexOfFirstAccount = indexOfLastAccount - accountsPerPage;
  const currentTransactions = filteredTransactions?.slice(
    indexOfFirstAccount,
    indexOfLastAccount
  );

  return (
    <Box
      mt={{ lg: !accounts ? "10vh" : "15vh", sm: "8vh" }}
      width={"100%"}
      height={"100%"}
    >
      {!accounts ? (
        <SkeletonTheme
          baseColor={"#646466"}
          highlightColor={"#a2a2a3"}
          width={"100%"}
        >
          <Skeleton
            borderRadius={30}
            width={{ lg: "83vw", sm: "100vw" }}
            height="80vh"
          />
        </SkeletonTheme>
      ) : (
        <Flex
          justify={"space-between"}
          wrap={{ sm: "wrap-reverse", lg: "nowrap" }}
          align="flex-start"
          borderRadius={"30px"}
        >
          <Flex
            justifyContent={
              currentTransactions?.length === 0 ||
              currentTransactions === undefined
                ? "center"
                : "start"
            }
            flexDirection={"column"}
            css={borderStyles()}
            alignItems={"center"}
            width={{ lg: "48%", sm: "100%" }}
            minHeight={{ lg: "70vh", "2xl": "70vh", md: "70vh", sm: "80vh" }} // Set a minimum height for the container
            borderRadius={"30px"}
            background={background}
            mt={{ sm: "5vh", lg: "0" }}
            position={"relative"}
            pb={{ sm: "5vh" }}
            pt={{ lg: "8vh", md: "7vh", sm: "8vh" }}
            padding={"30px"}
          >
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              position={"absolute"}
              top={{ lg: "20px", sm: "15px" }}
              left={"30px"}
            >
              <Select
                background={background}
                onChange={(e) => {
                  setSelectedAccount(Number(e.target.value));
                  setActiveAccount(Number(e.target.value));
                  setActiveTransaction(false);
                }}
                width={{ lg: "50%", sm: "80%" }}
                value={activeAccount}
              >
                {accounts?.map((account) => (
                  <option
                    value={account?.id}
                    style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
                  >
                    {account?.name}
                  </option>
                ))}
              </Select>
            </Box>
            {loading ? (
              <Box minH={"80%"}>
                <Spinner />
              </Box>
            ) : currentTransactions?.length > 0 && !loading ? (
              <Transactions
                data={currentTransactions}
                setItem={setActiveTransaction}
                activeAccount={activeAccount}
                main={true}
                height={"20%"}
              />
            ) : (
              "No transactions found"
            )}
            <Flex
              justify={"center"}
              width={{ lg: "100%", sm: "100%" }}
              mt={5}
              position={"absolute"}
              left={"0%"}
              bottom={"4%"}
              visibility={currentTransactions === undefined && "hidden"}
            >
              <Pagination
                currentPage={currentPage}
                paginate={setCurrentPage}
                itemsPerPage={accountsPerPage}
                totalItems={filteredTransactions?.length}
              />
            </Flex>
          </Flex>
          {activeTransaction && (
            <TransactionDetails
              activeTransaction={activeTransaction}
              setActiveTransaction={setActiveTransaction}
            />
          )}
        </Flex>
      )}
    </Box>
  );
};

export default Account;
