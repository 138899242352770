/* eslint-disable */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { verifyEmail as verification } from "api";
import illustration from "assets/img/auth/auth.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Verify() {
  const location = useLocation();
  const searchParams = new URLSearchParams(
    location.search.replace(/\?{2,}/, "?")
  );
  const token = searchParams.get("token");
  const history = useHistory();

  const verify = async (token) => {
    try {
      await verification(token);
      history.push("/kyb/verfication");
    } catch (error) {}
  };

  useEffect(() => {
    if (token) {
      verify(token);
    }
  }, [token]);

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "10px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "30vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Verifying Email
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Please hold on app is trying to verify the email.
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          justify="center"
          align="center"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Verify;
