/* eslint-disable */
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Europe from "assets/img/dashboards/europe.png";
import UK from "assets/img/dashboards/uk.png";
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import { useAccount } from "contexts/Context";
import { useEffect, useState } from "react";
import { borderStyles } from "theme/border";
import TotalSpent from "views/admin/default/components/TotalSpent";
import UserActivity from "./components/WeeklyRevenue";

export default function UserReports() {
  // Chakra Color Mode
  const boxBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  const {
    account: selectedAccount,
    changeAccount: setSelectedAccount,
    accounts,
  } = useAccount();
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const textColorSecondary = useColorModeValue(
    "secondaryGray.700",
    "whiteAlpha.600"
  );

  const getVal = (id, key) =>
    accounts?.find((account) => Number(id) === account.id)?.[key];
  useEffect(() => {
    if (selectedAccount === null) {
      accounts && accounts.length > 0 && setSelectedAccount(accounts[0]?.id);
    }
  }, [accounts]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "100px", sm: "45px" }}>
      <SimpleGrid
        columns={{ base: 2, md: 2, xl: 2, lg: 2, sm: 1 }}
        gap="20px"
        mb="20px"
      >
        <Box width={"100%"}>
          <Select
            background={boxBg}
            height={"50px"}
            width={"100%"}
            css={borderStyles()}
            value={selectedAccount}
            onChange={(e) => setSelectedAccount(e.target.value)}
          >
            {accounts?.map((account) => (
              <option
                style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
                value={account.id}
              >
                {account.name}
              </option>
            ))}
          </Select>
        </Box>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <Box
          display="flex"
          width={"100%"}
          background={boxBg}
          padding={"20px"}
          flexDirection={"column"}
          css={borderStyles()}
        >
          <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
            <Flex flexDirection={"column"} width={"50%"}>
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Text
                  fontSize={{ lg: "20px", sm: "12px" }}
                  color={textColorSecondary}
                >
                  Your Balance
                </Text>
                <select
                  style={{
                    display: "flex",
                    width: "55px",
                    background: boxBg,
                    marginLeft: "20px",
                    borderRadius: "2px",
                    fontSize: "12px",
                  }}
                  onChange={(e) => setSelectedCurrency(e.target.value)}
                >
                  <option
                    value={"EUR"}
                    style={{
                      background: "rgba(0,0,0,0.9)",
                      color: "white",
                      fontSize: { lg: "15px", sm: "8px" },
                    }}
                  >
                    ALL
                  </option>
                  <option
                    value="USD"
                    style={{
                      background: "rgba(0,0,0,0.9)",
                      color: "white",
                      fontSize: { lg: "15px", sm: "10px" },
                    }}
                  >
                    USD
                  </option>
                  <option
                    value="EUR"
                    style={{
                      background: "rgba(0,0,0,0.9)",
                      color: "white",
                      fontSize: { lg: "15px", sm: "10px" },
                    }}
                  >
                    EUR
                  </option>
                  <option
                    value="GBP"
                    style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
                  >
                    GBP
                  </option>
                </select>
              </Box>

              <Text
                fontSize={{ lg: "50px", sm: "2xl" }}
                display={"flex"}
                alignItems={"center"}
              >
                {(getVal(selectedAccount, "balance")
                  ? getVal(selectedAccount, "balance")
                  : 0)
                      ?.toString()
                      ?.concat(" ")
                      ?.concat(
                        selectedCurrency
                          ? selectedCurrency
                          : getVal(selectedAccount, "currency")
                          ? getVal(selectedAccount, "currency")
                          : "EUR"
                      )}
                <span style={{ fontSize: "20px", marginLeft: "10px" }}>{}</span>
              </Text>
            </Flex>
            <Flex mt="10px" alignItems={"center"}>
              <FormLabel htmlFor="balance">
                <Avatar
                  src={
                    selectedCurrency === "GBP"
                      ? UK
                      : selectedCurrency === "EUR"
                      ? Europe
                      : Usa
                  }
                />
              </FormLabel>
            </Flex>
          </Box>
          <Flex
            justifyContent={{ lg: "start", sm: "center" }}
            mt={{ sm: "10px" }}
          >
            <Button
              style={{
                background: boxBg,
              }}
              mr={"25px"}
              css={{ ...borderStyles("10px", true) }}
            >
              Send
            </Button>
            <Button
              style={{
                background: boxBg,
              }}
              css={borderStyles("10px", true)}
              mr={"25px"}
            >
              Request
            </Button>
            <Button
              style={{
                background: boxBg,
              }}
              css={borderStyles("10px", true)}
              mr={"2px"}
            >
              Exchange
            </Button>
          </Flex>
        </Box>
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 2, md: 2, xl: 2, sm: 1 }}
        gap="20px"
        mb="20px"
      >
        <TotalSpent
          accounts={accounts}
          selectedAccount={selectedAccount}
          setAccount={setSelectedAccount}
        />
        <UserActivity
          accounts={accounts}
          selectedAccount={selectedAccount}
          setAccount={setSelectedAccount}
        />
      </SimpleGrid>
    </Box>
  );
}
