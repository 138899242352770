/* eslint-disable */

import { BASE_URL } from "Baseurl";
import { useEffect, useState } from "react";
import apiInstance from "./interceptor";

// Function to handle registering a user
export const registerBeneficiary = async (data) => {
  try {
    const response = await apiInstance.post(
      `${BASE_URL}/beneficiary/register`,
      data
    );
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};
export function useGetBenefaciaries(indicator) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const user = localStorage.getItem("user");
  const fetch = async () => {
    if (user) {
      try {
        const response = await apiInstance.get(`${BASE_URL}/beneficiary/list`);
        setData(response?.data.rows);

        return;
      } catch (error) {
        setError(true);
      }
    }
  };
  useEffect(() => {
    fetch();
  }, [indicator]);
  return { data, error };
}
export function useUtils() {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const fetch = async () => {
    try {
      const countries = await apiInstance.get(`${BASE_URL}/utils/countries`);
      const currencies = await apiInstance.get(`${BASE_URL}/utils/currencies`);
      setData({
        countries: countries.data,
        currencies: currencies.data,
      });
      return;
    } catch (error) {
      setError(true);
    }
  };
  useEffect(() => {
    fetch();
  }, []);
  return { data, error };
}
