/* eslint-disable */
import { BASE_URL } from "Baseurl";
import axios from "axios";
import { useEffect, useState } from "react";
import apiInstance from "./interceptor";

// Function to handle registering a user
export const registerAccount = async (userData) => {
  try {
    const response = await axios.post(`${BASE_URL}/account/register`, userData);
    return response.data;
  } catch (error) {
    throw error.response.message;
  }
};

// Function to handle user login
export const useGetAccounts = (indicator) => {
  const [accounts, setAccounts] = useState();
  const fetch = async () => {
    try {
      const response = await apiInstance.get(`${BASE_URL}/account/list`);
      setAccounts(response.data);
      return accounts;
    } catch (error) {}
  };
  useEffect(() => {
    return fetch();
  }, [indicator]);
  return { accounts };
};
