import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { sendPayment, useGetBenefaciaries } from "api";
import { useAccount } from "contexts/Context";
import html2canvas from "html2canvas";
import Joi from "joi";
import { useRef, useState } from "react";
import { AiFillSave } from "react-icons/ai";
import { borderStyles } from "theme/border";

const CreatePaymentForm = () => {
  const initalState = {
    account_id: 0,
    reason: "",
    receiver: {},
  };
  const [formData, setFormData] = useState(initalState);

  const paymentReasons = [
    "Rent payment",
    "Utility bill payment",
    "Grocery shopping",
    "Dining out",
    "Shopping",
    "Entertainment expenses",
    "Transportation costs",
    "Medical expenses",
    "Education fees",
    "Charity donation",
  ];

  const [errors, setErrors] = useState({});
  const [receiptData, setReceiptData] = useState(null);

  const { accounts } = useAccount();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalRef = useRef();
  const [transferType, setTransferType] = useState("external");

  const { data } = useGetBenefaciaries();
  const toast = useToast();
  const background = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "receiver.beneficiary_id") {
      setErrors({
        [name]: "",
      });
      setFormData({
        ...formData,

        receiver: {
          ...formData.receiver,
          beneficiary_id: value,
        },
      });
    } else if (name === "receiver.account_id") {
      setErrors({
        [name]: "",
      });
      setFormData({
        ...formData,

        receiver: {
          ...formData.receiver,
          account_id: parseInt(value),
        },
      });
    } else {
      if (transferType !== "external" && name === "account_id") {
        setFormData({
          ...formData,
          [name]: value,
          currency: accounts?.find((account) => account.id === Number(value))
            .currency,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    }
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };
  
  const handleSubmit = async () => {
    const schema = Joi.object().keys({
      account_id: Joi.number().integer().required(),
      amount: Joi.number().required(),
      currency: Joi.string()
        .max(4)
        .when("receiver.account_id", { is: Joi.exist(), then: Joi.required() }),
      reason: Joi.string(),
      reference: Joi.string(),
      receiver: Joi.object({
        beneficiary_id: Joi.string(),
        account_id: Joi.number().integer(),
        member_id: Joi.number().integer(),
      })
        .xor("beneficiary_id", "account_id")
        .required(),
    });

    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      const validationErrors = {};
      error.details.forEach((detail) => {
        validationErrors[detail.path[0]] = detail.message;
      });
      setErrors(validationErrors);
      toast({
        title: "Validation Error",
        description: "Please fix the errors in the form.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await sendPayment(formData);
      toast({
        title: "Payment Sent",
        description: "Your payment was sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setReceiptData(response.data.data);
      onOpen();
    } catch (error) {
      toast({
        title: "Payment Failed",
        description: error,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const saveScreenshot = () => {
    if (modalRef.current) {
      html2canvas(modalRef.current).then((canvas) => {
        canvas.toBlob((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "payment_receipt.png";
          link.click();
          URL.revokeObjectURL(link.href);
        });
      });
    }
  };

  return (
    <Box
      boxShadow="md"
      p="4"
      borderRadius="lg"
      mx="auto"
      mt={{ lg: "12vh", sm: "7vh" }}
      width={"100%"}
      minHeight={{ lg: "80vh", sm: "100vh" }}
      display={"flex"}
      justifyContent={"center"}
      css={borderStyles()}
      background={background}
    >
      <Box
        spacing="2"
        display={"flex"}
        justifyContent={"center"}
        flexWrap={"wrap"}
        borderRadius={"20px"}
        padding={"30px"}
        width={"90%"}
        height={"80vh"}
        alignItems={"start"}
      >
        <Flex
          justify="space-evenly"
          width={{ lg: "70%", sm: "100%" }}
          my="10px"
        >
          <Button
            background={
              transferType === "external" ? "blackAlpha.500" : background
            }
            css={borderStyles()}
            fontSize={{ sm: "10px", lg: "lg" }}
            onClick={() => {
              setTransferType("external");
              setFormData(initalState);
            }}
          >
            External
          </Button>
          <Button
            fontSize={{ sm: "10px", lg: "lg" }}
            css={borderStyles()}
            background={
              transferType === "internal" ? "blackAlpha.500" : background
            }
            onClick={() => {
              setTransferType("internal");
              setFormData(initalState);
            }}
          >
            Internal
          </Button>
        </Flex>

        <FormControl id="account_id" width={{ lg: "70%", sm: "100%" }} my="5px">
          <FormLabel>Select Account</FormLabel>
          <Select
            onChange={handleInputChange}
            placeholder={"Select Account"}
            name="account_id"
          >
            {accounts?.map((account) => (
              <option
                key={account.id}
                value={account.id}
                style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
              >
                {account.name}
              </option>
            ))}
          </Select>
          {errors.account_id && (
            <Text color="red.500">{errors.account_id}</Text>
          )}
        </FormControl>
        <FormControl id="receiver" width={{ lg: "70%", sm: "100%" }} my="5px">
          <FormLabel>
            {transferType === "external"
              ? "Beneficiary Account"
              : "Receiver Account"}
          </FormLabel>
          <Select
            name={
              transferType === "external"
                ? "receiver.beneficiary_id"
                : "receiver.account_id"
            }
            onChange={handleInputChange}
            placeholder={
              transferType === "external"
                ? "Select Beneficiary"
                : "Select Account"
            }
          >
            {(transferType === "external"
              ? data
              : accounts?.filter(
                  (account) => account.currency === formData.currency
                )
            )
              ?.filter(
                (account) =>
                  transferType === "external" ||
                  account.id !== formData.account_id
              )
              .map((account) => (
                <option
                  key={account.id}
                  value={account.id}
                  style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
                >
                  {transferType === "external"
                    ? account?.company_name
                    : account.name}
                </option>
              ))}
          </Select>
          {errors.receiver && <Text color="red.500">{errors.receiver}</Text>}
        </FormControl>

        <FormControl id="amount" width={{ lg: "70%", sm: "100%" }} my="5px">
          <FormLabel>Amount</FormLabel>
          <Input
            type="number"
            name="amount"
            value={formData.amount}
            css={borderStyles()}
            color={"white"}
            onChange={handleInputChange}
            required
          />
          {errors.amount && <Text color="red.500">{errors.amount}</Text>}
        </FormControl>
        {transferType !== "external" && (
          <FormControl id="currency" width={{ lg: "70%", sm: "100%" }} my="5px">
            <FormLabel>Currency</FormLabel>
            <Input
              name="currency"
              value={formData.currency}
              disabled={true}
              color={"white"}
            />
            {errors.currency && <Text color="red.500">{errors.currency}</Text>}
          </FormControl>
        )}

        <FormControl id="reason" width={{ lg: "70%", sm: "100%" }} my="5px">
          <FormLabel>Reason</FormLabel>
          <Select
            placeholder="Choose a Reason"
            name="reason"
            css={borderStyles()}
            onChange={handleInputChange}
          >
            {paymentReasons.map((reason) => (
              <option
                key={reason}
                style={{ background: "rgba(0,0,0,0.9)", color: "white" }}
                value={reason}
              >
                {reason}
              </option>
            ))}
          </Select>
          {errors.reason && <Text color="red.500">{errors.reason}</Text>}
        </FormControl>

        <Box width={"100%"} textAlign={"center"} my="10px">
          <Button
            background={background}
            onClick={handleSubmit}
            width={{ lg: "70%", sm: "100%" }}
            css={borderStyles()}
          >
            Submit Payment
          </Button>
        </Box>
      </Box>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={modalRef}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent ref={modalRef} background={"black"} color={"white"}>
          <ModalHeader>
            <Flex alignItems="center">
              <Text fontSize="lg"> Ventus Payment Receipt</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody textAlign="center">
            {receiptData && (
              <Table variant="simple" size="md">
                <Tbody>
                  <Tr>
                    <Td>ID</Td>
                    <Td>{receiptData.id}</Td>
                  </Tr>
                  <Tr>
                    <Td>Status</Td>
                    <Td>{receiptData.status}</Td>
                  </Tr>
                  <Tr>
                    <Td>Amount</Td>
                    <Td>{receiptData.amount}</Td>
                  </Tr>
                  <Tr>
                    <Td>Currency</Td>
                    <Td>{receiptData.currency}</Td>
                  </Tr>
                </Tbody>
              </Table>
            )}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              background={background}
              leftIcon={<AiFillSave />}
              onClick={saveScreenshot}
              mr="4"
            >
              Save Receipt
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreatePaymentForm;
