/* eslint-disable */

import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { logout, sendVerificationEmail as verification } from "api";
import illustration from "assets/img/auth/auth.png";
import DefaultAuth from "layouts/auth/Default";

function VerifyEmail() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("user")));
  useEffect(() => {
    async function getData() {
      try {
        verification();
      } catch (error) {}
    }
    getData();
  }, []);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h={{ lg: "50%", sm: "80vh" }}
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "10px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "10px", md: "20vh" }}
        flexDirection="column"
      >
        <Box me="auto" alignItems={"center"}>
          <Heading
            color={textColor}
            fontSize="36px"
            mb="10px"
            width={{ sm: "100%", lg: "auto" }}
            textAlign={{ sm: "center", lg: "left" }}
          >
            Verify Email
          </Heading>
          <Flex direction={"column"} width={"100%"} mt={"50px"}>
            <Text
              width={{ lg: "70%", sm: "100%" }}
              textAlign={{ lg: "left", sm: "center" }}
            >
              Check your email{" "}
              <a href={`malito:${auth?.email}`} target="_blank">
                <span style={{ fontWeight: "bold", color: "green" }}>
                  {auth?.email}
                </span>
              </a>{" "}
              to verify and activate your account
            </Text>
            <Flex
              width={{ lg: "70%", sm: "100%" }}
              direction={{ sm: "column", lg: "row" }}
              align={"center"}
              justify={"space-evenly"}
              mt={"50px"}
            >
              <Button
                width={{ lg: "30%", sm: "50%" }}
                my={"10px"}
                onClick={verification}
              >
                Resend
              </Button>
              <Button
                width={{ lg: "30%", sm: "50%" }}
                my={"10px"}
                onClick={logout}
              >
                Logout
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </DefaultAuth>
  );
}

export default VerifyEmail;
