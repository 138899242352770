import {
  Box,
  Flex,
  Icon,
  Image,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import { getCVV, getFullCardNumber, getPin } from "api";
import chip from "assets/img/layout/chip.svg";
import logo from "assets/img/layout/logoWhite.svg";
import { useEffect, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Card = ({ card: CardData, accountsec }) => {
  const { colorMode } = useColorMode();
  const [card, setCard] = useState();
  const [visibleData, setVisibleData] = useState();
  const [loading, setLoading] = useState();

  const show = async () => {
    setLoading(true);
    const response = await getFullCardNumber(card.id);
    const response2 = await getCVV(card.id);
    const response3 = await getPin(card.id);
    
    setVisibleData((prev) => ({
      ...prev,
      pan_mask: response.data.card_number,
      cvv: response2.data.cvv,
      pin: response3.data,
    }));
    setLoading(false);
  };
  useEffect(() => {
    setCard(CardData);
    setVisibleData(null);
  }, [CardData]);

  return card ? (
    <Box
      w={{ lg: accountsec ? "100%" : "100%", sm: "100%", "4xl": "3rem" }}
      borderWidth="0.5px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      mt={{ sm: !accountsec && "5vh" }}
      height={{ lg: "32vh", sm: "auto" }}
      py={"20px"}
      px={"30px"}
      bg={
        colorMode === "dark"
          ? "linear-gradient(135deg, rgba(46,48,48,0.9899955343465511) 10%, rgba(6,6,14,0.9843932934501926) 100%)"
          : "gray.500"
      }
    >
      <Box
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={{ lg: 4, sm: 1 }}
      >
        <Image src={logo} width={{ lg: "150px", sm: "70px" }} />

        <Tooltip label={card.card_status} aria-label="Card Status">
          <Text
            color={
              card.card_status === "NOT_ACTIVATED"
                ? "red.500"
                : card.card_status === "ACTIVE"
                ? "green.500"
                : "gray.500"
            }
            fontSize={{ lg: "sm", sm: "xs" }}
            fontWeight="bold"
          >
            {card.card_status === "ACTIVE" ? "ACTIVE" : "INACTIVE"}
          </Text>
        </Tooltip>
      </Box>
      <Image
        src={chip}
        width={{ lg: "50px", sm: "30px" }}
        my={{ lg: "20px", sm: "10px" }}
        filter={"grayscale(100%) brightness(100%)"} // Silver effect
      />
      <Text
        fontSize={
          visibleData?.pan_mask
            ? { lg: "24px", sm: "12px", "2xl": "30px" }
            : { lg: "24px", sm: "12px", "2xl": "30px" }
        }
        letterSpacing={!visibleData?.pan_mask ? "3px" : "1px"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        fontFamily={"Orbitron, sans-serif"}
        fontWeight={"900"}
        justifyContent={!loading ? "start" : "start"}
      >
        {loading ? (
          <SkeletonTheme baseColor={"gray"} highlightColor={"#444"}>
            <Skeleton width={250} height={30} />
          </SkeletonTheme>
        ) : visibleData?.pan_mask ? (
          visibleData.pan_mask
        ) : (
          card.pan_mask
        )}{" "}
        {
          <Icon
            as={visibleData ? IoMdEyeOff : IoMdEye}
            ml={"10px"}
            onClick={visibleData ? () => setVisibleData(null) : show}
          />
        }
      </Text>
      <Flex justify={"space-between"} align={"flex-end"}>
        <Flex
          direction={"column"}
          width={"50%"}
          align={"start"}
          mt={{ lg: "20px", sm: "5px" }}
        >
          <Flex justify={"flex-end"}>
            <Flex
              fontFamily={"Orbitron, sans-serif"}
              align={"center"}
              justify={"end"}
            >
              <Text
                width={{ lg: "30px", sm: "22px" }}
                fontSize={{ lg: "9px", sm: "8px" }}
              >
                Valid From
              </Text>
              <Text fontSize={{ lg: "15px", sm: "12px" }}>{card.expiry}</Text>
            </Flex>
          </Flex>
          <Text
            fontFamily={"Orbitron, sans-serif"}
            fontSize={{ lg: "25px", sm: "12px" }}
          >
            {card?.emboss_name}
          </Text>
        </Flex>
        <Flex direction={"column"} align={"flex-end"}>
          <Text
            mb={1}
            display={"flex"}
            alignItems={"center"}
            fontFamily={"Orbitron, sans-serif"}
            fontSize={{ lg: "11px", sm: "8px" }}
          >
            CVV{" "}
            {loading ? (
              <SkeletonTheme baseColor={"gray"} highlightColor={"#444"}>
                <Skeleton width={30} height={10} />
              </SkeletonTheme>
            ) : visibleData?.pan_mask ? (
              visibleData.cvv
            ) : (
              card.cvv
            )}{" "}
          </Text>

          <Text
            mb={1}
            fontFamily={"Orbitron, sans-serif"}
            fontSize={{ lg: "11px", sm: "8px" }}
          >
            PIN{" "}
            {loading ? (
              <SkeletonTheme baseColor={"gray"} highlightColor={"#444"}>
                <Skeleton width={30} height={10} />
              </SkeletonTheme>
            ) : visibleData?.pin ? (
              visibleData.pin
            ) : (
              "****"
            )}
          </Text>
          <Image
            src="https://assets-global.website-files.com/619e93b13a08062a0f45577f/61b921888eccfa3229a588ee_Visa%20Logo%20Grey.png"
            alt="Mastercard Logo"
            width={{ lg: "70px", sm: "30px" }}
          />
        </Flex>
      </Flex>
    </Box>
  ) : (
    <></>
  );
};

export default Card;
