import {
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdControlPoint, MdHome, MdPayments } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";

export function SidebarLinks(props) {
  let location = useLocation();

  const { routes, onClose } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const activeColor = useColorModeValue("brand.500", "white");
  const inactiveColor = useColorModeValue("#949494", "#949494");
  const activeIcon = useColorModeValue("brand.500", "white");


  const renderCategory = (categoryName, routes) => {
    return (
      <Box key={categoryName} width={"100%"}>
        <Flex width={"100%"}>
          <Text
            fontSize="lg"
            fontWeight="bold"
            width="100%"
            color={
              routes.filter((route) => activeRoute(route.path.toLowerCase()))
                .length > 0
                ? activeColor
                : inactiveColor
            }
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
            // my="10px"
          >
            {categoryName === "Home" ? (
              <MdHome
                style={{ marginRight: "10px", width: "30px", height: "30px" }}
              />
            ) : categoryName === "Payments" ? (
              <MdPayments
                style={{ marginRight: "10px", width: "30px", height: "30px" }}
              />
            ) : (
              <MdControlPoint
                style={{ marginRight: "10px", width: "30px", height: "30px" }}
              />
            )}

            {categoryName}
          </Text>
        </Flex>
        <VStack align="stretch" spacing="4" width={"100%"} my="30px">
          {routes.map((route, index) => (
            <NavLink
              key={index}
              to={route.layout + route.path}
              onClick={onClose}
              width={"100%"}
            >
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "10px" : "10px"
                }
                transform={"translateX(25px)"}
                py="5px"
                width={"100%"}
              >
                <Box
                  color={
                    activeRoute(route.path.toLowerCase())
                      ? activeIcon
                      : inactiveColor
                  }
                  // me="3px"
                  transform={"translateY(3px)"}
                >
                  {route.icon}
                </Box>
                <Text
                  me="auto"
                  color={
                    activeRoute(route.path.toLowerCase())
                      ? activeColor
                      : inactiveColor
                  }
                  fontWeight={
                    activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
                  }
                >
                  {route.name}
                </Text>
              </HStack>
            </NavLink>
          ))}
        </VStack>
        <Box borderBottom="1px solid #505050" my="5px" width="100%" />
      </Box>
    );
  };

  const renderCategories = () => {
    const categories = {};
    routes.forEach((route) => {
      if (!categories[route.compType]) {
        categories[route.compType] = [];
      }
      categories[route.compType].push(route);
    });

    return Object.entries(categories).map(([categoryName, categoryRoutes]) =>
      renderCategory(categoryName, categoryRoutes)
    );
  };

  return (
    <VStack align="stretch" spacing="4">
      {renderCategories()}
    </VStack>
  );
}

export default SidebarLinks;
