import { css } from "@emotion/react";
export const borderStyles = (borderRadius, button) =>
  css({
    position: "relative",
    //   display: "inline-block",
    borderRadius: borderRadius ? borderRadius : "10px", // Adjust border radius as needed
    "&::before": {
      content: '""',
      position: "absolute",
      inset: 0,
      borderRadius: borderRadius ? borderRadius : "10px", // Adjust border radius as needed
      padding: "1.5px", // Control the border thickness
      background: button
        ? "linear-gradient(104.59deg, rgba(255, 255, 255, 0.3) 0.76%, rgba(255, 255, 255, 0.0768416) 32.78%, rgba(255, 255, 255, 0.220526) 69.11%, rgba(255, 255, 255, 0.021) 99%)"
        : "linear-gradient(104.59deg, rgba(255, 255, 255, 0.8) 0.76%, rgba(255, 255, 255, 0.204911) 32.78%, rgba(255, 255, 255, 0.58807) 69.11%, rgba(255, 255, 255, 0.056) 99%)",
      WebkitMask: `
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0)
      `,
      overflow: "hidden",
      
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
      pointerEvents: "none",
    },
  });
