/* eslint-disable */
import {
  Box,
  Button,
  Center,
  Checkbox,
  Code,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useColorModeValue,
  useToast,
  IconButton,
} from "@chakra-ui/react";

import { requestQRCODE, updateCompany, updateOtp } from "api";
import { resetPasswordWhenLoggedIn, verifyOtp } from "api/auth";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import { QRCode } from "react-qrcode";
import { borderStyles } from "theme/border";

export const Email = ({ isOpen, onClose, oldEmail, companyId }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    oldEmail: "",
    newEmail: "",
    email_address: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    oldEmail: "",
    newEmail: "",
    email_address: "",
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const modalBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)"
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const errors = {};

    if (formData.oldEmail !== oldEmail) {
      errors.oldEmail = "Old email does not match.";
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.newEmail)) {
      errors.newEmail = "New email must be a valid email address.";
    }
    if (formData.newEmail === oldEmail) {
      errors.newEmail = "New email must be different from old email.";
    }
    if (formData.newEmail !== formData.email_address) {
      errors.email_address = "New email and confirm new email must match.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setIsConfirmOpen(true);
    }
  };

  const handleConfirmSave = async () => {
    setIsConfirmOpen(false);
    try {
      await updateCompany(companyId, {
        company_email: formData.email_address,
      });
      toast({
        title: "Account email updated.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onClose();
      window.location.reload();
    } catch (error) {}
  };

  const handleClose = () => {
    setIsConfirmOpen(false);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(4px)",
            zIndex: 1000, // Adjust this value as needed
          }}
        />
      )}
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          background={modalBg}
          css={borderStyles()}
          width={{ sm: "80%" }}
        >
          <ModalHeader display={"flex"} justifyContent={"space-between"}>
            Change Email Address
            <IconButton
              icon={<IoMdClose />}
              onClick={onClose}
              aria-label="Close modal"
            />
          </ModalHeader>
          <ModalBody>
            <FormControl my={"15px"} isInvalid={!!validationErrors.oldEmail}>
              <FormLabel>Old Email</FormLabel>
              <Input
                color={"white"}
                name="oldEmail"
                value={formData.oldEmail}
                onChange={handleChange}
              />
              {validationErrors.oldEmail && (
                <p style={{ color: "red" }}>{validationErrors.oldEmail}</p>
              )}
            </FormControl>
            <FormControl my={"15px"} isInvalid={!!validationErrors.newEmail}>
              <FormLabel>New Email</FormLabel>
              <Input
                color={"white"}
                name="newEmail"
                value={formData.newEmail}
                onChange={handleChange}
              />
              {validationErrors.newEmail && (
                <p style={{ color: "red" }}>{validationErrors.newEmail}</p>
              )}
            </FormControl>
            <FormControl
              my={"15px"}
              isInvalid={!!validationErrors.confirmNewEmail}
            >
              <FormLabel>Confirm New Email</FormLabel>
              <Input
                color={"white"}
                name="email_address"
                value={formData.email_address}
                onChange={handleChange}
              />
              {validationErrors.email_address && (
                <p style={{ color: "red" }}>{validationErrors.email_address}</p>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              background={modalBg}
              css={borderStyles()}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              background={modalBg}
              css={borderStyles()}
              onClick={handleSave}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isConfirmOpen} isCentered onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          background={modalBg}
          css={borderStyles()}
          width={{ sm: "80%" }}
        >
          <ModalHeader>Confirm Email Change</ModalHeader>
          <ModalBody>
            Are you sure you want to change your email address?
          </ModalBody>
          <ModalFooter>
            <Button
              background={modalBg}
              css={borderStyles()}
              mr={3}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              background={modalBg}
              css={borderStyles()}
              onClick={handleConfirmSave}
            >
              Yes, change it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const PhoneNumber = ({ isOpen, onClose, oldPhone, companyId }) => {
  const toast = useToast();
  const [formData, setFormData] = useState({
    oldPhone: "",
    newPhone: "",
    phone_number: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    oldPhone: "",
    newPhone: "",
    phone_number: "",
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const modalBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)"
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [name]: "",
    });
  };

  const validateForm = () => {
    const errors = {};

    if ("+".concat(formData.oldPhone) !== oldPhone) {
      errors.oldPhone = "Old phone number does not match.";
    }

    if (formData.phone_number === oldPhone) {
      errors.newPhone = "New phone must be different from old.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setIsConfirmOpen(true);
    }
  };

  const handleConfirmSave = async () => {
    setIsConfirmOpen(false);
    try {
      await updateCompany(companyId, {
        company_phone_number: "+".concat(formData.phone_number),
      });
      toast({
        title: "Account phone number updated.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      onClose();
      window.location.reload();
    } catch (error) {}
  };

  const handleClose = () => {
    setIsConfirmOpen(false);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(4px)",
            zIndex: 1000, // Adjust this value as needed
          }}
        />
      )}
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          background={modalBg}
          css={borderStyles()}
          width={{ sm: "80%" }}
        >
          <ModalHeader display={"flex"} justifyContent={"space-between"}>
            Change Phone Number
            <IconButton
              icon={<IoMdClose />}
              onClick={onClose}
              aria-label="Close modal"
            />
          </ModalHeader>
          <ModalBody>
            <FormControl my={"15px"} isInvalid={!!validationErrors.oldPhone}>
              <FormLabel>Old Phone Number</FormLabel>
              <PhoneInput
                inputStyle={{
                  background: "transparent",
                  color: "white",
                  width: "100%",
                  border: "0.1px solid gray",
                  height: "45px",
                  borderRadius: "5px",
                }}
                buttonStyle={{ background: "transparent", border: "none" }}
                containerStyle={{ border: "none" }}
                dropdownStyle={{ color: "black" }}
                value={formData.oldPhone}
                onChange={(phone) =>
                  setFormData((prev) => ({
                    ...prev,
                    ["oldPhone"]: phone,
                  }))
                }
              />
              {validationErrors.oldPhone && (
                <p style={{ color: "red" }}>{validationErrors.oldPhone}</p>
              )}
            </FormControl>

            <FormControl
              my={"15px"}
              isInvalid={!!validationErrors.phone_number}
            >
              <FormLabel> New Phone Number</FormLabel>
              <PhoneInput
                inputStyle={{
                  background: "transparent",
                  color: "white",
                  width: "100%",
                  border: "0.1px solid gray",
                  height: "45px",
                  borderRadius: "5px",
                }}
                buttonStyle={{ background: "transparent", border: "none" }}
                containerStyle={{ border: "none" }}
                dropdownStyle={{ color: "black" }}
                value={formData.phone_number}
                onChange={(phone) =>
                  setFormData((prev) => ({
                    ...prev,
                    ["phone_number"]: phone,
                  }))
                }
              />
              {validationErrors.phone_number && (
                <p style={{ color: "red" }}>{validationErrors.phone_number}</p>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              background={modalBg}
              css={borderStyles()}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              background={modalBg}
              css={borderStyles()}
              onClick={handleSave}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isConfirmOpen} isCentered onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          background={modalBg}
          css={borderStyles()}
          width={{ sm: "80%" }}
        >
          <ModalHeader>Confirm Email Change</ModalHeader>
          <ModalBody>
            Are you sure you want to change your email address?
          </ModalBody>
          <ModalFooter>
            <Button
              background={modalBg}
              css={borderStyles()}
              mr={3}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              background={modalBg}
              css={borderStyles()}
              onClick={handleConfirmSave}
            >
              Yes, change it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const ResetPassword = ({ isOpen, onClose, onSave }) => {
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordAgain: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordAgain: "",
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState();
  const toast = useToast();

  const modalBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)"
  );

  const handleClose = () => {
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords({
      ...passwords,
      [name]: value,
    });
  };
  const validateForm = () => {
    const errors = {};

    if (
      passwords.oldPassword === passwords.newPassword ||
      passwords.oldPassword === passwords.newPasswordAgain
    ) {
      errors.oldPassword = "Old and new passwords cannot be same.";
    }

    if (passwords.newPassword !== passwords.newPasswordAgain) {
      errors.newPasswordAgain =
        "New Password and confirm password should be same.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSave = async () => {
    if (validateForm()) {
      setIsConfirmOpen(true);
    } else {
      toast({
        title: "Your Password reset attempt failed",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
    }
  };

  const handleSumbit = async () => {
    try {
      await resetPasswordWhenLoggedIn(passwords);
      toast({
        title: "Your Password is reset successfully",
        status: "success",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
      setIsConfirmOpen(false);
      onClose();
    } catch (error) {
      toast({
        title: "Your Password reset attempt failed",
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
    }
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(4px)",
            zIndex: 1000, // Adjust this value as needed
          }}
        />
      )}
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent
          background={modalBg}
          css={borderStyles()}
          width={{ sm: "80%" }}
        >
          <ModalHeader display={"flex"} justifyContent={"space-between"}>
            Change Password
            <IconButton
              icon={<IoMdClose />}
              onClick={onClose}
              aria-label="Close modal"
            />
          </ModalHeader>
          {/* <ModalCloseButton onClick={onClose} /> */}
          <ModalBody>
            <FormControl my={"15px"} isInvalid={!!validationErrors.oldPassword}>
              <FormLabel>Old Password</FormLabel>
              <Input
                color={"white"}
                type="password"
                name="oldPassword"
                value={passwords.oldPassword}
                onChange={handleChange}
              />
              {validationErrors?.oldPassword && (
                <p style={{ color: "red" }}>{validationErrors.oldPassword}</p>
              )}
            </FormControl>
            <FormControl my={"15px"} isInvalid={!!validationErrors.newPassword}>
              <FormLabel>New Password</FormLabel>
              <Input
                color={"white"}
                type="password"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handleChange}
              />
              {validationErrors?.newPassword && (
                <p style={{ color: "red" }}>{validationErrors.newPassword}</p>
              )}
            </FormControl>
            <FormControl
              my={"15px"}
              isInvalid={!!validationErrors.newPasswordAgain}
            >
              <FormLabel>Confirm Password</FormLabel>
              <Input
                color={"white"}
                type="password"
                name="newPasswordAgain"
                value={passwords.newPasswordAgain}
                onChange={handleChange}
              />
              {validationErrors?.newPasswordAgain && (
                <p style={{ color: "red" }}>
                  {validationErrors.newPasswordAgain}
                </p>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={onClose}
              background={modalBg}
              css={borderStyles()}
              color={"white"}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              background={modalBg}
              css={borderStyles()}
              color={"white"}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isConfirmOpen} isCentered onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          background={modalBg}
          css={borderStyles()}
          width={{ sm: "80%" }}
        >
          <ModalHeader>Confirm Phone Number Change</ModalHeader>
          <ModalBody>
            Are you sure you want to change your phone number?
          </ModalBody>
          <ModalFooter>
            <Button
              background={modalBg}
              css={borderStyles()}
              mr={3}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              background={modalBg}
              css={borderStyles()}
              onClick={handleSumbit}
            >
              Yes, change it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export const TwoFactorAuthentication = ({ isOpen, onClose, email }) => {
  const [step, setStep] = useState(1);

  const [authPendingStatus] = useState({
    email: JSON.parse(localStorage.getItem("user")).emailAuthStatus,
    app: JSON.parse(localStorage.getItem("user")).appAuthStatus,
  });
  const [otpRadio, setOtpRadio] = useState({
    isAppAuthEnabled: false,
    isEmailAuthEnabled: false,
    password: "",
    totp: "",
    otp: "",
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState();
  const [qrCode, setQrCode] = useState();
  const toast = useToast();

  const modalBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -60.13%, #303337 -40%, rgba(26, 26, 26, 0.9) 100%)"
  );
  const handleSave = async () => {
    setIsConfirmOpen(true);
  };
  const requrestQr = async () => {
    try {
      const request = await requestQRCODE();
      const tempUrl = new URL(request.qr.replace("otpauth://", "http://dummy"));
      const searchParams = new URLSearchParams(tempUrl.search);
      const secret = searchParams.get("secret");
      setQrCode({ qr: request.qr, secret: secret });
    } catch (error) {}
  };

  useEffect(() => {
    if (
      authPendingStatus.email === "PENDING" ||
      authPendingStatus.app === "PENDING"
    ) {
      setStep(
        authPendingStatus.app === "PENDING"
          ? 2
          : authPendingStatus.email === "PENDING"
          ? 3
          : true
      );
      if (authPendingStatus.app === "PENDING") requrestQr();
    }
  }, []);
  useEffect(() => {
    if (step === 2) {
      requrestQr();
    }
  }, [step]);

  const requestEmailOtp = async () => {
    try {
      await updateOtp({
        isEmailAuthEnabled: true,
        password: otpRadio.password,
      });
      toast({
        title: "success",
        description: "Please check your email for otp",
        status: "success",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
    } catch (error) {}
  };
  const handleSubmit = async () => {
    try {
      await updateOtp(
        otpRadio.isEmailAuthEnabled
          ? {
              isEmailAuthEnabled:
                authPendingStatus.email === "ENABLED" ? false : true,
              password: otpRadio.password,
            }
          : {
              isAppAuthEnabled:
                authPendingStatus.app === "ENABLED" ? false : true,

              password: otpRadio.password,
            }
      );

      if (otpRadio.isAppAuthEnabled && authPendingStatus.app !== "ENABLED") {
        setIsConfirmOpen(false);
        setStep(2);
        setOtpRadio({
          isAppAuthEnabled: false,
          isAppAuthEnabled: false,
        });
      } else if (
        otpRadio.isEmailAuthEnabled &&
        authPendingStatus.email !== "ENABLED"
      ) {
        setIsConfirmOpen(false);
        setStep(3);
        setOtpRadio({
          isAppAuthEnabled: false,
          isAppAuthEnabled: false,
          password: "",
        });
      } else {
        window.location.reload();
      }
    } catch (error) {
      setIsConfirmOpen(false);
      toast({
        title: "Error",
        description: error?.response?.data.message,
        status: "error",
        duration: 2000,
        position: "top",
        isClosable: true,
      });
    }
  };
  const handleVerify = async () => {
    if (step === 3 ? otpRadio.otp?.length !== 6 : otpRadio.totp?.length !== 6) {
      toast({
        title: "Invalid Otp",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      return;
    } else {
      try {
        await verifyOtp(
          step === 4 ? otpRadio.totp : otpRadio.otp,
          step === 4 ? "appAuthStatus" : "emailAuthStatus",
          true,
          email
        );

        toast({
          title: "Verification successful",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      } catch (error) {
        toast({
          title: "Invalid Otp",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };
  const handleClose = () => {
    setIsConfirmOpen(false);

    setStep(1);
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return authPendingStatus.email === "ENABLED" &&
          authPendingStatus.app !== "ENABLED" &&
          !otpRadio.isAppAuthEnabled ? (
          <FormControl my="15px">
            <FormLabel>
              {authPendingStatus.email === "ENABLED"
                ? "Disable Email OTP"
                : "Enable Email OTP"}
            </FormLabel>
            <Checkbox
              isChecked={otpRadio.isEmailAuthEnabled}
              onChange={(e) =>
                setOtpRadio((prev) => ({
                  ...prev,
                  ["isEmailAuthEnabled"]: e.target.checked,
                }))
              }
              colorScheme="green"
            >
              {authPendingStatus.email === "ENABLED"
                ? "Disable Email OTP"
                : "Enable Email OTP"}
            </Checkbox>
          </FormControl>
        ) : authPendingStatus.app === "ENABLED" &&
          authPendingStatus.email !== "ENABLED" &&
          !otpRadio.isEmailAuthEnabled ? (
          <FormControl my="15px">
            <FormLabel>
              {authPendingStatus.app === "ENABLED"
                ? "Disable App OTP"
                : "Enable App OTP"}
            </FormLabel>
            <Checkbox
              isChecked={otpRadio.isAppAuthEnabled}
              onChange={(e) =>
                setOtpRadio((prev) => ({
                  ...prev,
                  ["isAppAuthEnabled"]: e.target.checked,
                }))
              }
              colorScheme="green"
            >
              {authPendingStatus.app === "ENABLED"
                ? "Disable App OTP"
                : "Enable App OTP"}
            </Checkbox>
          </FormControl>
        ) : (
          <>
            {!otpRadio.isAppAuthEnabled && (
              <FormControl my="15px">
                <FormLabel>
                  {authPendingStatus.email === "ENABLE"
                    ? "Disable Email OTP"
                    : "Enable Email OTP"}
                </FormLabel>
                <Checkbox
                  isChecked={otpRadio.isEmailAuthEnabled}
                  onChange={(e) =>
                    setOtpRadio((prev) => ({
                      ...prev,
                      ["isEmailAuthEnabled"]: e.target.checked,
                    }))
                  }
                  colorScheme="green"
                >
                  {authPendingStatus.email === "ENABLE"
                    ? "Disable Email OTP"
                    : "Enable Email OTP"}
                </Checkbox>
              </FormControl>
            )}
            {!otpRadio.isEmailAuthEnabled && (
              <FormControl my="15px">
                <FormLabel>
                  {authPendingStatus.app === "ENABLE"
                    ? "Disable App OTP"
                    : "Enable App OTP"}
                </FormLabel>
                <Checkbox
                  isChecked={otpRadio.isAppAuthEnabled}
                  onChange={(e) =>
                    setOtpRadio((prev) => ({
                      ...prev,
                      ["isAppAuthEnabled"]: e.target.checked,
                    }))
                  }
                  colorScheme="green"
                >
                  {authPendingStatus.app === "ENABLE"
                    ? "Disable App OTP"
                    : "Enable App OTP"}
                </Checkbox>
              </FormControl>
            )}
          </>
        );
      case 2:
        return (
          <VStack spacing={4} align="center">
            <Heading size="md">
              Scan this QR code with your authenticator app
            </Heading>
            <Box borderWidth="1px" borderRadius="lg" padding="4">
              <QRCode
                value={qrCode?.qr}
                style={{ width: "200px", height: "200px" }}
              />
            </Box>
            <Text>Or enter this secret key into your app:</Text>
            <Code fontSize={{ sm: "10px", lg: "15px" }}>{qrCode?.secret}</Code>
            <Text style={{ color: "yellow", opacity: "0.7" }}>
              Next step will be confirmation step. it will require code from
              authenticator app in order to enable it. are you sure you setup
              above qr code in authenticator app?
            </Text>
          </VStack>
        );
      case 3:
        return (
          <VStack spacing={4} align="center">
            <Input
              placeholder="Enter the OTP code"
              style={{ margin: "20px 0px", color: "white" }}
              type="number"
              value={otpRadio.otp}
              onChange={(e) =>
                setOtpRadio((prev) => ({ ...prev, otp: e.target.value }))
              }
            />
            <span style={{ borderTop: "1px solid gray" }}></span>
            <Text>Request Email Verification code again </Text>
            <Input
              placeholder="Enter the password "
              style={{ margin: "20px 0px", color: "white" }}
              type="password"
              value={otpRadio.password}
              onChange={(e) =>
                setOtpRadio((prev) => ({ ...prev, password: e.target.value }))
              }
            />
          </VStack>
        );
      case 4:
        return (
          <VStack spacing={4} align="center">
            <Input
              placeholder="Enter the OTP code"
              style={{ margin: "20px 0px", color: "white" }}
              value={otpRadio.totp}
              type="number"
              onChange={(e) =>
                setOtpRadio((prev) => ({ ...prev, totp: e.target.value }))
              }
            />
          </VStack>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(4px)",
            zIndex: 1000,
          }}
        />
      )}
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <Center>
          <ModalContent
            background={modalBg}
            css={borderStyles()}
            width={{ sm: "80%" }}
          >
            <ModalHeader display={"flex"} justifyContent={"space-between"}>
              <Text>
                {step === 1 && "Select OTP Type"}
                {step === 2 && "Enter OTP Details"}
                {step === 3 && "Enter Email OTP "}
                {step === 4 && "Enter App OTP "}
              </Text>
              <IconButton
                icon={<IoMdClose />}
                onClick={onClose}
                aria-label="Close modal"
              />
            </ModalHeader>
            <ModalBody>
              {renderStepContent()}
              {(otpRadio.isEmailAuthEnabled || otpRadio.isAppAuthEnabled) &&
                step === 1 && (
                  <Input
                    placeholder="Enter your Password"
                    value={otpRadio.password}
                    onChange={(e) =>
                      setOtpRadio((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                    style={{ color: "white" }}
                    type="password"
                  />
                )}
            </ModalBody>
            <ModalFooter
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              {step === 2 && (
                <Button
                  mr={3}
                  onClick={() => {
                    setStep(4);
                  }}
                  background={modalBg}
                  color="white"
                >
                  Next
                </Button>
              )}
              {(step === 3 ? otpRadio.password === "" : step !== 2 && true) && (
                <Button
                  onClick={step !== 3 && step !== 4 ? handleSave : handleVerify}
                  css={borderStyles()}
                  background={modalBg}
                  color="white"
                >
                  Save
                </Button>
              )}
              {step === 3 && otpRadio.password !== "" && (
                <Button
                  onClick={requestEmailOtp}
                  css={borderStyles()}
                  background={modalBg}
                  color="white"
                >
                  Request
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Center>
      </Modal>
      <Modal isOpen={isConfirmOpen} isCentered onClose={handleClose}>
        <ModalOverlay />
        <ModalContent
          background={modalBg}
          css={borderStyles()}
          width={{ sm: "80%" }}
        >
          <ModalHeader>Confirm Change OTP Options</ModalHeader>
          <ModalBody>
            {`Are you sure you want to ${
              authPendingStatus.app === "ENABLE" && otpRadio.isEmailAuthEnabled
                ? "disable email your otp?"
                : !authPendingStatus.app !== "ENABLE" &&
                  otpRadio.isEmailAuthEnabled
                ? "enable your email otp?"
                : !authPendingStatus.app === "ENABLE" &&
                  otpRadio.isAppAuthEnabled
                ? "enable your authenticator app otp?"
                : "disable your authenticator app otp?"
            }`}
          </ModalBody>
          <ModalFooter>
            <Button
              background={modalBg}
              css={borderStyles()}
              mr={3}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              background={modalBg}
              css={borderStyles()}
              onClick={handleSubmit}
            >
              Yes, change it
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
