/* eslint-disable */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { register } from "api";
import { City, Country } from "country-state-city";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { signupValidation } from "validations";
import countriesdata from "./countriesdata.json";

// Custom Input Component
const CustomInput = ({
  label,
  value,
  onChange,
  type = "text",
  error,
  country,
}) => {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  
  return (
    <FormControl isInvalid={error} mb="24px" width={{ lg: "40%", sm: "100%" }}>
      <FormLabel
        ms="4px"
        fontSize="sm"
        fontWeight="500"
        color={textColor}
        display={"flex"}
        justifyContent={"flex-start"}
      >
        {label}
        <Text color={brandStars} ml={"5px"}>
          *
        </Text>
      </FormLabel>
      <InputGroup size="md">
        <Input
          type={type}
          value={value}
          onChange={onChange}
          variant="auth"
          size="lg"
          fontSize="sm"
        />

        {error && (
          <InputRightElement>
            <Text color="red.500">*</Text>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

function SignUp() {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const background = useColorModeValue("whiteAlpha.900", "whiteAlpha.100");

  const history = useHistory();
  const toast = useToast();
  const types = [
    "text",
    "email",
    "password",
    "password",
    "text",
    "text",
    "text",
    "text",
    "tel",
    "address",
    "address",
    "text",
  ];
  const autocomplete = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "street-address",
    "street-address",
    "zip-code",
  ];
  const companyTypes = [
    "INDIVIDUAL",
    "SOLE_TRADER",
    "PARTNERSHIP",
    "LIMITED_PARTNERSHIP",
    "LIMITED_LIABILITY_PARTNERSHIP",
    "PRIVATE_LIMITED_COMPANY",
    "PUBLIC_LIMITED_COMPANY",
    "CHARITABLE_INCORPORATED_ORGANISATION",
    "COMMUNITY_INTEREST_COMPANY",
  ];
  const [formData, setFormData] = useState({
    company_name: "",
    company_email: "",
    password: "",
    confirm_password: "",
    company_registration_number: "",
    company_type: "",
    country: "",
    city: "",
    company_phone_number: "",
    address_line_1: "",
    address_line_2: "",
    zip_code: "",
  });
  useEffect(() => {}, [formData.country, formData.city]);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the field
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    let { confirm_password, ...rest } = formData;
    let updatedCountry = countriesdata.find(
      (entity) => entity.country === formData.country
    )?.value;
    rest.country = updatedCountry;
    rest.company_phone_number = "+".concat(rest.company_phone_number);
    const validationErrors = signupValidation(rest);
    if (validationErrors) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      try {
        const response = await register(rest, toast);
        if (response) {
          toast({
            title: "Account Registered Successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setTimeout(() => {
            history.push("/auth/login");
          }, 2000);
        }

        setFormData({
          company_name: "",
          company_email: "",
          password: "",
          company_registration_number: "",
          company_type: "",
          company_phone_number: "",
          country: "",
          city: "",
          address_line_1: "",
          address_line_2: "",
          zip_code: "",
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };
  const capitalizeFirstLetter = (str) => {
    return str.replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  };

  return (
    <Flex
      direction="column"
      maxW={{ base: "100%", md: "max-content", lg: "100%" }}
      mx={{ base: "auto", lg: "auto" }}
      me="auto"
      h="100%"
      alignItems="center"
      justifyContent="center"
      mb={{ base: "30px", md: "10px" }}
      px={{ base: "25px", md: "0px" }}
      mt={{ base: "40px", md: "10vh" }}
    >
      <Box mx="auto" textAlign={"center"}>
        <Text color={textColor} fontSize="36px" mb="10px">
          Sign Up
        </Text>
        <Text
          mb="36px"
          ms="4px"
          color="gray.400"
          fontWeight="400"
          fontSize="md"
        >
          Fill the Information to Sign up!
        </Text>
      </Box>
      <Flex
        direction="row"
        w={{ base: "100%", md: "60%" }}
        maxW="100%"
        flexWrap="wrap"
        background="transparent"
        borderRadius="15px"
        justifyContent={{
          xl: "space-between",
          lg: "space-between",
          sm: "center",
        }}
        mx={{ base: "auto", lg: "auto" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        {Object.keys(formData).map((key, index) =>
          key !== "country" &&
          key !== "city" &&
          key !== "company_type" &&
          key !== "company_phone_number" ? (
            <CustomInput
              key={key}
              label={capitalizeFirstLetter(key.replace(/_/g, " "))}
              value={formData[key]}
              onChange={(e) =>
                handleChange({
                  target: { name: key, value: e.target.value },
                })
              }
              type={types[index]}
              error={errors[key]}
              autocomplete={autocomplete[index]}
            />
          ) : (
            <FormControl
              isInvalid={errors[key]}
              mb="24px"
              width={{ lg: "40%", sm: "100%" }}
            >
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display={"flex"}
                justifyContent={"flex-start"}
              >
                {capitalizeFirstLetter(key.replace(/_/g, " "))}
                <Text color={brandStars} ml={"5px"}>
                  *
                </Text>
              </FormLabel>
              {key === "company_phone_number" ? (
                <PhoneInput
                  inputStyle={{
                    background: "transparent",
                    color: "white",
                    width: "100%",
                    border: "0.1px solid gray",
                    height: "45px",
                    borderRadius: "15px",
                  }}
                  buttonStyle={{ background: "transparent", border: "none" }}
                  containerStyle={{ border: "none" }}
                  dropdownStyle={{ color: "black" }}
                  country={formData.country.toLowerCase()}
                  value={formData.company_phone_number}
                  onChange={(phone) =>
                    setFormData((prev) => ({
                      ...prev,
                      ["company_phone_number"]: phone,
                    }))
                  }
                />
              ) : (
                <Select
                  placeholder={`Select ${capitalizeFirstLetter(
                    key.replace(/_/g, " ")
                  )}`}
                  onChange={(e) => {
                    setFormData((prev) => ({
                      ...prev,
                      [key]: e.target.value,
                      city:
                        key === "country"
                          ? City.getAllCities(e.target.value)[0]
                          : e.target.value,
                    }));

                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      [key]: "", // Clear the error for the field
                    }));
                  }}
                  size="lg"
                  borderRadius={"12px"}
                  height={"50px"}
                  bg={"transparent"}
                >
                  {(key === "country"
                    ? Country.getAllCountries()
                    : key === "city"
                    ? City.getCitiesOfCountry(
                        formData.country ? formData.country : "PK"
                      )
                    : companyTypes
                  ).map((keyy) => (
                    <option
                      value={
                        key === "country"
                          ? keyy.isoCode
                          : key === "company_type"
                          ? keyy
                          : keyy.name
                      }
                      style={{ width: "100", color: "white" }}
                    >
                      {key === "company_type"
                        ? keyy.replace(/_/g, " ")
                        : keyy.name}
                    </option>
                  ))}
                </Select>
              )}

              {<FormErrorMessage>{errors[key]}</FormErrorMessage>}
            </FormControl>
          )
        )}
        <Flex
          direction={"column"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={{ md: "15vh" }}
          mt={"20px"}
        >
          <Button
            fontSize="sm"
            background={background}
            fontWeight="500"
            w="60%"
            h="50"
            mb="24px"
            onClick={handleSubmit}
          >
            Register
          </Button>
          <Flex direction="column" justifyContent="center" alignItems="start">
            <Text color="gray.400" fontWeight="400" fontSize="14px">
              Already have an account?{" "}
              <NavLink to="/auth/sign-in">
                <Text as="span" color="brand.500" fontWeight="500" ms="5px">
                  login
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
