import {
  Button,
  ButtonGroup,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { borderStyles } from "theme/border";

const Pagination = ({ currentPage, itemsPerPage, totalItems, paginate }) => {
  const [small] = useMediaQuery("(max-width: 768px)");
  const boxBg = useColorModeValue(
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)",
    "radial-gradient(135.33% 135.33% at 5.71% -44.13%, #303337 0%, rgba(26, 26, 26, 0.27) 100%)"
  );

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const chunkSize = small ? 3 : 5;

  // Calculate the start and end page numbers for the current chunk
  const startPage = Math.floor((currentPage - 1) / chunkSize) * chunkSize + 1;
  const endPage = Math.min(startPage + chunkSize - 1, totalPages);

  // Render page buttons for the current chunk
  const renderPageButtons = () => {
    const buttons = [];
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => paginate(i)}
          background={i === currentPage ? "gray.300" : boxBg}
          color={i === currentPage ? "black" : "white"}
          css={borderStyles(false, false)}
        >
          {i}
        </Button>
      );
    }
    return buttons;
  };

  return (
    <ButtonGroup>
      {startPage > 1 && (
        <Button
          css={borderStyles(false, false)}
          background={boxBg}
          onClick={() => paginate(startPage - 1)}
        >
          {"< "}
        </Button>
      )}
      {renderPageButtons()}
      {endPage < totalPages && (
        <Button
          css={borderStyles(false, false)}
          background={boxBg}
          onClick={() => paginate(endPage + 1)}
        >
          {"> "}
        </Button>
      )}
    </ButtonGroup>
  );
};

export default Pagination;
