import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetAccounts, useGetBenefaciaries } from "api";
import { useCardList } from "api";

const AccountContext = createContext();

export const useAccount = () => useContext(AccountContext);

export const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [refreshAccounts, setRefreshAccounts] = useState(false);
  const { accounts: currentAccounts } = useGetAccounts(refreshAccounts);
  const { data: benefaciaries } = useGetBenefaciaries(refreshAccounts);
  const { data: cards } = useCardList(refreshAccounts);

  useEffect(() => {
    if (currentAccounts) {
      setAccounts(currentAccounts);
    }
  }, [refreshAccounts, currentAccounts]);

  const changeAccount = (newAccount) => {
    setAccount(Number(newAccount));
  };

  return (
    <AccountContext.Provider
      value={{
        account,
        changeAccount,
        setAccounts,
        accounts,
        setRefreshAccounts,
        refreshAccounts,
        benefaciaries,
        cards,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
